import config from "../../config/App.config";

//Users
export const userUrl = `${config.API_URL}/User`;
export const usersUrl = `${config.API_URL}/Users`;

//Emails
export const usersEmailUrl = `${config.API_URL}/B2b/Users`;

//OTA
export const firmwareLatestOta = `${config.API_URL}/Firmwares/otas`;

//Filters Ota Firmware
export const firmwareFilters = `${config.API_URL}/ProductTypes`;

//Use base URL for ChangeLog and Download Firmware
export const firmwareUrl = `${config.API_URL}/Firmwares`;

//PROD
export const firmwareLatestProd = `${config.API_URL}/Firmwares/prods`;

//VIRGIN
export const firmwareLatestVirgin = `${config.API_URL}/Firmwares/Virgins`;

//Products Url
export const ProductsTypesUrl = `${config.API_URL}/ProductTypes/all`;

//Models Url
export const ModelTypesUrl = `${config.API_URL}/ModelTypes/all`;

//Bms Type Url
export const BmsTypesUrl = `${config.API_URL}/bms/AllTypes`;

//Driver Types Url
export const DriverTypesUrl = `${config.API_URL}/drivers/AllTypes`;

//Motor Url
export const MotorTypesUrl = `${config.API_URL}/MotorTypes`;

//Map
export const HubsLatestTelemetry = `${config.API_URL}/Telemetries/Hubs`;

//Geo Location
export const geoLocationTelemetry = `${config.API_URL}/B2b/Geosearch`;

//Manufacturer Graphs
export const ManufacturerGraphs = `${config.API_URL}/Telemetries/Hubs/Stats`;

//Projected Range Graphs
export const HubsProjectedRange = `${config.API_URL}/Telemetries/Hubs/ProjectedRange`;

//Hubs Data Aio Utils
export const hubUrl = `${config.API_URL}/B2b/Hub`;

//MotorType url
export const motorTypeUrl = `${config.API_URL}/MotorTypes`;

//Util Url
export const utilUrl = `${config.API_URL}/Utils`;

//GROUPED FIRMWARE STATS
export const firmwareStatsGroupListUrl = `${config.API_URL}/B2b/FirmwareStats`;

//DISTRIBUTION PRODUCTS STATS
export const distributionProductGroupList = `${config.API_URL}/B2b/ProductsStats`;

//Company Management
export const companyUrl = `${config.API_URL}/Companies`;

// Finish Product
export const FinishProductUrl = `${config.API_URL}/ProductCodes`;

// First top Ranking
export const FirstRankingUrl = `${config.API_URL}/Telemetries/Hubs`;

// Telemetries Faults
export const telemetries = `${config.API_URL}/Telemetries/`;

//Faults
export const faultsList = `${config.API_URL}/Telemetries/FaultsList`;

//Test Report
export const testList = `${config.API_URL}/Tests`;

//Brands Manufacturer Management
export const brandsUrl = ``;

//Models Manufacturer Management
export const ModelsUrl = ``;

//Vehicles Manufacturer Management
export const VehiclesUrl = ``;

//Vehicles Locations
export const vehiclesLocationUrl = ``;

//QualityCheck
export const QualityCheck = `${config.API_URL}/Tests/QualityCheck`;

//Endurance
export const Endurance = `${config.API_URL}/Tests/Endurance`;

//Components
export const Components = `${config.API_URL}/Components`;

//Market Zone
export const marketZoneUrl = ``;

//Countries
export const countriesList = `${config.API_URL}/Countries`;

//Configurations
export const TestConfigurations = `${config.API_URL}/Tests/QualityCheck/Configs`;

//HubsLifeCyclesCounts
export const HubsLifeCyclesCounts = `${config.API_URL}/B2b/Hubs/LifeCycles/Counts`;
export const GetHubsLifeCyclesAsExcel = `${config.API_URL}/B2b/Hubs/LifeCycles/xlsx`;

//HubsActiveInMyBikeCounts
export const HubsActiveInMyBikeCounts = `${config.API_URL}/B2b/Hubs/ActiveInMyBike/Counts`;

//ProductCodes Generations
export const GenerationUrl = `${config.API_URL}/B2b`;

//Products Hubs MyBike Portal
export const productsUrl = `${config.API_URL}/Products`;

//Products Details Hubs MyBike Portal
export const productsDetailsUrl = `${config.API_URL}/Products/hub`;


