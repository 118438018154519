import { toast } from "../components/ui/use-toast";

export function showValidationErrorToast(message: string, title?: string) {
  toast({
    title: title ?? "Error",
    variant: "destructive",
    description: message,
    duration: 2000,
  });
}

export function showSuccessMessageToast(message: string) {
  toast({
    title: "Success",
    variant: "accent",
    description: message,
    duration: 2000,
  });
}

export function showNoDataMessageToast(message: string) {
  toast({
    title: "No Data",
    variant: "default",
    description: message,
    duration: 2000,
  });
}
