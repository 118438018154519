import {
  LogLevel,
  PublicClientApplication,
  Configuration
} from "@azure/msal-browser";

const config = {
  DEFAULT_ROW_COUNT: 20,
  MAX_ROW_COUNT: 1000,
  API_URL: "https://zehus-api-common-testing.azure-api.net/MyBikeApp/v2",
  CLIENT_ID: "548c39b5-6c56-4ae4-813d-5cd3086396ab",
  AUTHORITY: "https://mybikeuserstesting.b2clogin.com/tfp/mybikeuserstesting.onmicrosoft.com/b2c_1_signupandsignin",
  SCOPES: "https://mybikeuserstesting.onmicrosoft.com/ApiManagement.MyBike/MyBikeApi.Customer",
  //CORS_PROXY_URL: "http://localhost:9980/",
  TESTING_API_URL:
    "https://zehus-api-common-testing.azure-api.net/MyBikeApp/v2",
  TESTING_SCOPES:
    "https://mybikeuserstesting.onmicrosoft.com/ApiManagement.MyBike/MyBikeApi.Customer",
  TESTING_AUTHORITY:
    "https://mybikeuserstesting.b2clogin.com/tfp/mybikeuserstesting.onmicrosoft.com/B2C_1_SignUpAndSignIn",
  TESTING_CLIENT_ID: "548c39b5-6c56-4ae4-813d-5cd3086396ab",
  INTEGRATION_API_URL:
    "https://zehus-api-common-integration.azure-api.net/MyBikeApp/v2",
  INTEGRATION_SCOPES:
    "https://mybikeusersintegr.onmicrosoft.com/apimanagement/MyBikeApi.Customer",
  INTEGRATION_AUTHORITY:
    "https://mybikeusersintegr.b2clogin.com/tfp/mybikeusersintegr.onmicrosoft.com/B2C_1_SignUpAndSignIn",
  INTEGRATION_CLIENT_ID: "b88e537f-51e6-48be-9415-4104123a8ec5",
  VERSION_APP: "0.1"
};

export const isDev = () =>
  process.env.NODE_ENV === "development" ? true : false;

export const sortArrayByBigNumber = (arr: number[]): number[] => {
  return arr.sort((a, b) => b - a);
};

if (isDev()) {
  const isTesting = true;

  if (isTesting) {
    config.AUTHORITY = config.TESTING_AUTHORITY;
    config.CLIENT_ID = config.TESTING_CLIENT_ID;
    config.SCOPES = config.TESTING_SCOPES;
    config.API_URL = config.TESTING_API_URL;
  } else {
    config.AUTHORITY = config.INTEGRATION_AUTHORITY;
    config.CLIENT_ID = config.INTEGRATION_CLIENT_ID;
    config.SCOPES = config.INTEGRATION_SCOPES;
    config.API_URL = config.INTEGRATION_API_URL;
  }
}

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_B2B_login",
    postLogoutRedirectUri: "/"
  },
  authorities: {
    signUpSignIn: {
      authority: config.AUTHORITY
    }
  },
  authorityDomain: config.AUTHORITY
};
export const msalConfig: Configuration = {
  auth: {
    clientId: config.CLIENT_ID,
    authority: config.AUTHORITY,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);

            return;
          case LogLevel.Info:
            //console.info(message);

            return;
          case LogLevel.Verbose:
            //console.debug(message);

            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};
export const loginRequest = {
  scopes: [config.SCOPES],
  redirectStartPage: "/"
};

export const environment = {
  isDevelopment: config.API_URL.includes("integration"),
  isTesting: config.API_URL.includes("testing")
};

export const msalObj = new PublicClientApplication(msalConfig);

export default config;

export function accessTokenRequest() {
  return {
    scopes: [config.SCOPES],
    account: msalObj.getAllAccounts()[0]
  };
}
