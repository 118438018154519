import { Card, CardContent } from "../../../../components/ui/card";
import { Skeleton } from "../../../../components/ui/skeleton";
import { cn } from "../../../../lib/utils";

export interface ProductSkeletonProps {
  className?: string
}

const ProductSkeleton = (props: ProductSkeletonProps) => {

  return (
    <Card className={cn("card-overlay cursor-pointer p-2 shadow-lg rounded-lg", props.className)}>
      <CardContent className='flex items-center p-0'>
        <Skeleton className='mx-2 my-1 w-16 h-16 rounded-full' />
        <div className='flex flex-col items-start p-2'>
          <Skeleton className="mt-1 h-3 w-40 rounded-full" />
          <Skeleton className="mt-2 h-2 w-20 rounded-full" />
          <Skeleton className="mt-2 h-2 w-60 rounded-full" />
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductSkeleton;
