import "./menuBar.css";
import Logo from "../../../assets/images/LOGO H WHITE.svg";
import { FC, ReactElement, useState } from "react";
import { Bell, UserCircle } from "lucide-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import { SignOutButton } from "../../../app/pages/Login/SignOutButton";
import { SignInButton } from "../../../app/pages/Login/SignInButton";
import { useNavigate } from "react-router-dom";
import { cn } from "../../../lib/utils";
import { t } from "i18next";

export interface MenuBarProps {
  className?: string;
  onItemClicked?: () => void;
}

const MenuBar: FC<MenuBarProps> = (props: MenuBarProps): ReactElement => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const username = accounts.length > 0 ? accounts[0].name : "";

  const [isNotificato, setIsNotificato] = useState(true);

  const navigate = useNavigate();

  return (
    <div
      className={cn(
        "bg-titlebar flex flex-row items-center justify-between p-0",
        props.className
      )}
    >
      <div className='flex items-center justify-start space-x-4'>
        <img
          className='w-3 ms-4 pointer-events-none'
          src={Logo}
          alt='Zehus logo'
        />
        <Button
          className='text-primary rounded-none'
          variant='ghost'
          onClick={() => navigate("/sessions")}
        >
          {t("Sessions")}
        </Button>
      </div>

      <div className='flex flex-row justify-center items-center '>
        <Button
          className='flex justify-center text-white items-center rounded-none relative' // Aggiungi relative qui
          title='Account'
          variant='ghost'
          onClick={() => setIsNotificato(!isNotificato)} // Cambia lo stato al click
        >
          <Bell />
          {isNotificato && (
            <span className='absolute top-1 right-4 h-3 w-3 bg-green-500 rounded-full' />
          )}
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className='flex justify-end text-white items-center rounded-none'
              title='Account'
              variant='ghost'
            >
              <div className='sidebar-button-icon'>
                <UserCircle />
              </div>
              {username}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent style={{ width: "16rem" }}>
            <DropdownMenuItem>
              {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default MenuBar;

{
  /* <DropdownMenuSeparator />
<DropdownMenuItem>

  <ModeToggle />
</DropdownMenuItem>
<DropdownMenuSeparator />

<LanguageSwitcher /> */
}
