import { FC, ReactElement, useEffect, useState } from "react";
import "./app/globals.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./components/ui/theme-provider";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import config from "./config/App.config";
import {
  InteractionRequiredAuthError,
  InteractionStatus
} from "@azure/msal-browser";
import {
  addAxiosBearerToken,
  addAxiosCustomSerializer,
  addAxiosInterceptors
} from "./common/axios";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "./components/ui/alert-dialog";
import {
  CacheStoreState,
  clearErrors,
  setAccessToken,
  setWebView,
  useCacheStore
} from "./app/services";
import { Toaster } from "./components/ui/toaster";
import { useTranslation } from "react-i18next";
import { Button } from "./components/ui/button";
import NotFoundPage from "./app/pages/Auth/NotFoundPage";
import SplashScreen from "./app/pages/Login/SplashScreen";
import PrincipalPage from "./app/pages/HomePage/PrincipalPage";
import PageMap from "./app/pages/HomePage/Map/PageMap";
import { LanguageProvider } from "./lib/LanguageContext";
import i18n from "./common/i18n";
import MenuBar from "./components/custom/menubar/MenuBar";
import MenuBarMobile from "./components/custom/menubarMobile/MenuBarMobile";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
// import { sendWebViewMessage } from "./common/webview";

export interface DecodedToken {
  oid: string;
  sub?: string;
  preferred_username?: string;
  upn?: string;
  tid: string;
  email: string;
  name: string;
  exp: number;
}

const App: FC = (): ReactElement => {
  const { instance, inProgress, accounts } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const [isAuthenticated, setIsAuthenticated] =
    useState<boolean>(isMsalAuthenticated);
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const { t } = useTranslation();
  const errors = useCacheStore((state: CacheStoreState) => state.errors);
  const prompt = useCacheStore((state: CacheStoreState) => state.prompt);
  const isWebView = useCacheStore((state: CacheStoreState) => state.isWebView);
  // const [accountInfo, setAccountInfo] = useState<DecodedToken | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lang = params.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [location.search]);

  useEffect(() => {
    if (isMsalAuthenticated) {
      addAxiosCustomSerializer();
      setIsAuthenticated(true);
    }
  }, [isMsalAuthenticated]);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(location.search);
      const isWebView = params.get("wv");
      const accessToken = params.get("jwt");

      if (isWebView) {
        setWebView();
        if (accessToken) {
          setAccessToken(accessToken);
          addAxiosBearerToken(accessToken);
        }
        addAxiosInterceptors(instance, true);
        addAxiosCustomSerializer();
        setIsAuthenticated(true);
        return;
      }

      if (accounts.length > 0 && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          scopes: [config.SCOPES],
          account: accounts[0]
        };
        try {
          const accessTokenResponse = await instance.acquireTokenSilent(
            accessTokenRequest
          );
          addAxiosBearerToken(accessTokenResponse.accessToken);
          addAxiosInterceptors(instance, false);
          addAxiosCustomSerializer();
        } catch (error) {
          console.error("Error acquiring token silently:", error);
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        }
      }
    })();
  }, [instance, accounts, inProgress]);

  return (
    <ThemeProvider storageKey='vite-ui-theme'>
      <LanguageProvider initialLang={location.search}>
        <BrowserRouter>
          {((isMsalAuthenticated && inProgress === InteractionStatus.None) ||
            isAuthenticated) && (
            <>
              <div className='h-full w-full fade-in'>
                {/* CONVERT SIDEBAR IN TOP-BAR */}
                {!isWebView && (
                  <MenuBar className='hidden lg:flex sticky top-0 z-[75]' />
                )}
                {!isWebView && (
                  <MenuBarMobile className='flex lg:hidden sticky top-0 z-[75]' />
                )}
                {/* {
                    isWebView && <Button onClick={() => sendWebViewMessage("TokenExpired")}>TEST POST MESSAGE</Button>
                  } */}
                <Routes>
                  <Route path='/' element={<PrincipalPage />} />
                  <Route path='/sessions' element={<PrincipalPage />} />
                  <Route
                    path='/sessions/:hubId/:sessionId'
                    element={<PageMap />}
                  />
                  <Route path='*' element={<NotFoundPage />} />
                </Routes>
              </div>

              {/* Token Expiration Alert */}
              <AlertDialog open={isTokenExpired}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle> {t("TokenExpired")}</AlertDialogTitle>
                    <AlertDialogDescription>
                      {t("TokenExpiredMessage")}.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogAction asChild autoFocus>
                      <Button onClick={() => setIsTokenExpired(false)}>
                        ok
                      </Button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>

              {/* Error Message */}
              <AlertDialog open={errors && errors.length > 0}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Error</AlertDialogTitle>
                    <AlertDialogDescription></AlertDialogDescription>
                  </AlertDialogHeader>
                  {errors.map((error, index) => (
                    <p key={index}>{t(error)}</p>
                  ))}
                  <AlertDialogFooter>
                    <AlertDialogAction asChild autoFocus>
                      <Button onClick={() => clearErrors()}>Continue</Button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
              {/* Toast Notifications */}
              <Toaster />

              <ToastContainer
                position='top-right'
                autoClose={500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='dark'
              />
              {/* Loading Generic
            <AlertDialog open={loading?.scope === "__global"}>
              <AlertDialogContent>
                <div className='flex items-center'>
                  <Loader />
                  <div className='ml-6 text-sm'>{loading?.message}</div>
                </div>
              </AlertDialogContent>
            </AlertDialog> */}
              {/* Confirm operation */}
              <AlertDialog open={prompt !== null}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {prompt?.title ?? "Confirm"}
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      {prompt?.message}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel asChild>
                      <Button
                        size='sm'
                        variant='outline'
                        onClick={() => prompt?.callback(false)}
                      >
                        {t("Cancel")}
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button
                        size='sm'
                        variant='destructive'
                        onClick={() => prompt?.callback(true)}
                      >
                        {prompt?.confirmTextKey
                          ? t(prompt?.confirmTextKey)
                          : t("Confirm")}
                      </Button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
          {!isAuthenticated && (
            <SplashScreen
              loading={isWebView || inProgress !== InteractionStatus.None}
            />
          )}
        </BrowserRouter>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;
