import React, { createContext, useState, useEffect } from "react";

interface LanguageContextType {
  lang: string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

interface LanguageProviderProps {
  children: React.ReactNode;
  initialLang?: string;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
  initialLang = "en"
}) => {
  const [lang] = useState<string>(initialLang);

  useEffect(() => {
    console.log(`Language set to: ${lang}`);
  }, [lang]);

  return (
    <LanguageContext.Provider value={{ lang }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook to use the LanguageContext
export const useLanguage = () => {
  const context = React.useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
