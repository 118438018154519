import config from "../../config/App.config";

export const Footer = () => {
  return (
    <div className='text-center p-4'>
      <p className='text-sm'>
        Copyright &copy; 2024 ZEHUS | human + Version {config.VERSION_APP}
      </p>
    </div>
  );
};
