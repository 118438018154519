import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { environment } from "../config/App.config";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      default: ["en"],
      it: ["en"]
    },
    debug: environment.isDevelopment,

    interpolation: {
      escapeValue: false
    },

    backend: {
      loadPath: "/locales/{{lng}}/translation.json"
    },

    detection: {
      order: ["localStorage", "cookie", "sessionStorage", "navigator"],
      caches: ["localStorage", "cookie"]
    },

    saveMissing: true,
    saveMissingTo: "current",
    missingKeyHandler: (lng, ns, key) => {
      console.warn(
        `Missing translation key: ${key} in namespace ${ns} for language ${lng}`
      );
    }
  });

export default i18n;
