import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
// import am5themes_Light from "@amcharts/amcharts5/themes/Light";
import { useEffect, useRef } from "react";
import { SessionTelemetrySteps } from "../../../Models/API/ProductsDataResponseModels";
import "../../../globals.css";
import { useTheme } from "../../../../components/ui/theme-provider";
import { cn } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";

interface ChartGPSInfoProps {
  data: SessionTelemetrySteps[];
  className?: string;
}

// const convertDurationToDate = (duration: string): Date => {
//   const a = duration.split(":");
//   const fromStart = +a[0] * 3600 + +a[1] * 60 + +a[2];
//   return new Date(
//     0,
//     0,
//     0,
//     fromStart / 3600,
//     (fromStart % 3600) / 60,
//     fromStart % 60
//   );
// };

const ChartGPSInfo = (props: ChartGPSInfoProps) => {
  const { t } = useTranslation();
  const chartRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();

  const searchParams = new URLSearchParams(window.location.search);
  const urlMode = searchParams.get("mode");

  useEffect(() => {
    if (!chartRef.current) return;

    const root = am5.Root.new(chartRef.current);
    const am5themes: am5.Theme[] = [am5themes_Animated.new(root)];

    if (urlMode === null || urlMode === "dark") {
      am5themes.push(am5themes_Dark.new(root));
    } else {
      am5themes.push(am5themes_Animated.new(root));
    }
    root.setThemes(am5themes);

    const resizeObserver = new ResizeObserver(() => {
      root.resize();
    });
    resizeObserver.observe(chartRef.current);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelable: false,
        draggable: false,
        pinchZoomX: false,
        pinchZoomY: false
      })
    );

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        paddingTop: 16,
        paddingBottom: 16,
        numberFormat: "#.00' Km'",
        max: props.data.at(-1)?.distance,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50
        })
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 11,
      minPosition: 0.05,
      location: 0,
      maxPosition: 0.95,
      rotation: 45
    });

    // Add Y Axis (Altitude axis)
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeWidth: 2
        }),
        width: 48,
        paddingRight: 8,
        // eslint-disable-next-line prefer-spread
        min: Math.max(
          0,
          // eslint-disable-next-line prefer-spread
          Math.min.apply(
            Math,
            props.data.map((x) => x.gpsInfo.altitude)
          ) - 30
        ),
        // eslint-disable-next-line prefer-spread
        max:
          // eslint-disable-next-line prefer-spread
          Math.max.apply(
            Math,
            props.data.map((x) => x.gpsInfo.altitude)
          ) + 10,
        numberFormat: "#' m'"
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 11,
      location: 0.5
    });

    const altitudeSeries = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: t("Altitudine"),
        xAxis: xAxis,
        // tension: 0,
        stroke: am5.color("#0x4caf50"),
        minDistance: 10,
        yAxis: yAxis,
        valueYField: "altitude",
        valueXField: "distance",
        tooltip: am5.Tooltip.new(root, {
          labelText: t("Altitudine") + " {valueY} m"
        })
      })
    );

    altitudeSeries.fills.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            opacity: 1,
            color: am5.color("#0x4caf50"),
            offset: 0.25
          },
          {
            opacity: 0,
            color: am5.color("#0x4caf50"),
            offset: 1
          }
        ],
        rotation: 90
      })
    );

    altitudeSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 1
    });

    altitudeSeries.data.setAll(
      props.data.map((step) => ({
        altitude: step.gpsInfo.altitude,
        distance: step.distance
      }))
    );

    altitudeSeries.appear(1000);
    chart.appear(1000, 100);

    // Cleanup and unobserve resize
    return () => {
      root.dispose();
      resizeObserver.disconnect();
    };
  }, [props.data, theme]);

  return <div className={cn("h-[300px]", props.className)} ref={chartRef} />;
};

export default ChartGPSInfo;
