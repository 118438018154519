import { FC, ReactElement, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/App.config";
import Logo from "../../../assets/images/logo.svg";
import LogoLight from "../../../assets/images/logo-light.svg";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "../../../components/ui/button";
import { Footer } from "../../../components/custom/Footer";
import { useTheme } from "../../../components/ui/theme-provider";
import { useTranslation } from "react-i18next";

export interface SplashScreenProps {
  loading: boolean;
}

const SplashScreen: FC<SplashScreenProps> = (
  props: SplashScreenProps
): ReactElement => {
  const { instance, inProgress } = useMsal();
  const { theme } = useTheme();
  const { t } = useTranslation();

  const handleLogin = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }, [instance]);

  return (
    <>
      <div
        className={
          "w-screen h-screen flex flex-col justify-center items-center bg-muted fade-in"
        }
      >
        <div className='grow flex flex-col justify-center items-center'>
          <img
            className=''
            src={theme === "dark" ? Logo : LogoLight}
            width='600'
            alt='img'
          />

          {!props.loading && (
            <Button
              className='mt-12 px-8 fade-in'
              disabled={inProgress !== InteractionStatus.None}
              onClick={() => handleLogin()}
            >
              {t("LogIn")}
            </Button>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SplashScreen;
