import React from "react";
import { createRoot } from "react-dom/client";
import "./app/globals.css";
import App from "./App";
import { msalObj } from "./config/App.config";
import { MsalProvider } from "@azure/msal-react";
import "./common/i18n";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <React.Fragment>
        <MsalProvider instance={msalObj}>
          <App />
        </MsalProvider>
      </React.Fragment>
    </React.StrictMode>
  );
}
