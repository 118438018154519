import { create } from "zustand";
import { ProductsDetailsDataResponseModels, ProductsDetailsSessionDataResponseModels, Session } from "../../Models/API/ProductsDataResponseModels";

export interface Prompt {
  title?: string;
  message: string;
  confirmTextKey?: string;
  callback: (accepted: boolean) => void;
}
export interface CacheStoreState {
  vehicles: ProductsDetailsDataResponseModels[] | null;
  sessions: Session[] | null;
  cache: ProductsDetailsSessionDataResponseModels[];
  errors: string[];
  prompt: Prompt | null;
  isWebView: boolean;
  accessToken: string | null;
}

export const useCacheStore = create<CacheStoreState>(() => ({
  vehicles: null,
  sessions: null,
  errors: [],
  cache: [],
  prompt: null,
  claims: null,
  isWebView: false,
  accessToken: null
}));
