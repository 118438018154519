import { PropsWithChildren } from "react";
import { range } from "../../../../common/utils";
import { cn } from "../../../../lib/utils";
import "../../../globals.css";
import { ProductsDetailsDataResponseModels } from "../../../Models/API/ProductsDataResponseModels";
import { CacheStoreState, useCacheStore } from "../../../services";
import Product from "./Product";
import ProductSkeleton from "./ProductSkeleton";
import { useTranslation } from "react-i18next";

export interface ProductsProps {
  onItemClick?: (p: ProductsDetailsDataResponseModels) => void;
  className?: string;
  defaultOpen?: boolean;
}

const Products = (props: PropsWithChildren<ProductsProps>) => {
  const { t } = useTranslation();
  const vehicles = useCacheStore((state: CacheStoreState) => state.vehicles);

  return (
    <>
      <h1 className='text-green-500 xl:hidden m-2'>{t("MyVehicles")}</h1>

      <div
        className={cn(
          "flex flex-col sm:grid grid-cols-2 lg:flex",
          props.className
        )}
      >
        {props.children}
        {vehicles === null &&
          range(0, 2).map((index) => (
            <ProductSkeleton key={"vehicle_skeleton_" + index} />
          ))}
        {vehicles != null && vehicles.length > 0 && (
          <>
            {vehicles.map((vehicle, index) => (
              <Product
                key={"vehicle_" + index}
                product={vehicle}
                className='sticky top-0'
                defaultOpen={props.defaultOpen}
              ></Product>
            ))}
          </>
        )}
        {vehicles != null && vehicles.length === 0 && (
          <p>{t("VehicleNotFound")}</p>
        )}
      </div>
    </>
  );
};

export default Products;
