import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { msalObj, accessTokenRequest } from "../../config/App.config";
import { useCacheStore } from "./store";

const BaseService = {

  async ensureToken() {
    try {

      if (useCacheStore.getState().isWebView) {
        // in webview token will be injected by the mobile app
        axios.defaults.headers.common["Authorization"] = `Bearer ${useCacheStore.getState().accessToken}`;
        return;
      }

      // retrive access token from MSAL
      const accessTokenResponse = await msalObj.acquireTokenSilent(accessTokenRequest());
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessTokenResponse.accessToken}`;

    } catch (error) {
      console.error("ensureToken", error);
    }
  },


  async get<TResponse>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<TResponse>> {
    await this.ensureToken();
    return await axios.get<TResponse>(url, {
      ...config
    });
  },

  async delete<TResponse>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    await this.ensureToken();
    return await axios.delete<TResponse>(url, config);
  },

  async post<TResponse>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    await this.ensureToken();
    return await axios.post<unknown, AxiosResponse<TResponse>>(
      url,
      data,
      config
    );
  },

  async put<T>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    await this.ensureToken();
    return await axios.put<T>(url, data, config);
  },

  async patch<T>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    await this.ensureToken();
    return await axios.patch<T>(url, data, config);
  }
};

export default BaseService;
