import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { endOfDay } from "date-fns";
import { showValidationErrorToast } from "../common/toast";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function timeout(milliSeconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliSeconds));
}

export function emailValidation(email: string) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

export function validProductCodeDesc(
  productCodeId: string,
  productCodeDesc: string
) {
  if (productCodeId) {
    if (/^YB991/.test(productCodeId)) {
      if (!productCodeDesc || !productCodeDesc.endsWith(" GEN 2.0")) {
        showValidationErrorToast(
          "Product Code Description must ends with 'GEN 2.0' for YB991"
        );
        return false;
      }
      return true;
    } else if (/^YB992/.test(productCodeId)) {
      if (!productCodeDesc || !productCodeDesc.endsWith(" GEN 2.1")) {
        showValidationErrorToast(
          "Product Code Description must ends with 'GEN 2.1' for YB992"
        );
        return false;
      }
      return true;
    } else if (/^YB993/.test(productCodeId)) {
      if (!productCodeDesc || !productCodeDesc.endsWith(" GEN 2.2")) {
        showValidationErrorToast(
          "Product Code Description must ends with 'GEN 2.2' for YB993"
        );
        return false;
      }
      return true;
    } else if (/^YB994/.test(productCodeId)) {
      if (!productCodeDesc || !productCodeDesc.endsWith(" GEN 3.0")) {
        showValidationErrorToast(
          "Product Code Description must ends with 'GEN 3.0' for YB994"
        );
        return false;
      }
      return true;
    }
  }
  return false;
}

export function handleSelectToFilterCalendar(
  selectedDate: Date | undefined,
  setTo: React.Dispatch<React.SetStateAction<Date | undefined>>
) {
  if (selectedDate instanceof Date) {
    const endOfSelectedDay = endOfDay(selectedDate);
    const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000.0);
    const endOfCurrentDay = new Date(currentTimestampInSeconds * 1000);

    if (endOfSelectedDay > endOfCurrentDay) {
      setTo(endOfCurrentDay);
    } else {
      setTo(endOfSelectedDay);
    }
  }
}
