import React, { useEffect, useRef } from "react";
import { cn } from "../../../../lib/utils";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { useTheme } from "../../../../components/ui/theme-provider";
import { SessionTelemetrySteps } from "../../../Models/API/ProductsDataResponseModels";

interface ChartPowerMotorDistanceProps {
  data: SessionTelemetrySteps[];
  className?: string;
}

const ChartPowerMotorDistance: React.FC<ChartPowerMotorDistanceProps> = ({
  data,
  className
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();

  const searchParams = new URLSearchParams(window.location.search);
  const urlMode = searchParams.get("mode");

  useEffect(() => {
    if (!chartRef.current) return;

    const root = am5.Root.new(chartRef.current);
    const am5themes: am5.Theme[] = [am5themes_Animated.new(root)];

    if (urlMode === null || urlMode === "dark") {
      am5themes.push(am5themes_Dark.new(root));
    }
    root.setThemes(am5themes);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelable: false,
        draggable: false,
        pinchZoomX: false,
        pinchZoomY: false
      })
    );

    const lastDistance = data.length > 0 ? data.at(-1)?.distance ?? 0 : 0;

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        paddingTop: 16,
        paddingBottom: 16,
        numberFormat: "#.00' Km'",
        max: lastDistance,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50
        })
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 11,
      minPosition: 0.05,
      location: 0,
      maxPosition: 0.95,
      rotation: 45
    });

    const maxPowerMotor =
      Math.max(
        ...data.map((step) => (step.motorPower ? Number(step.motorPower) : 0))
      ) * 1.1;
    const minPowerMotor =
      Math.min(
        ...data.map((step) => (step.motorPower ? Number(step.motorPower) : 0))
      ) * 1.1;

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeWidth: 2
        }),
        width: 48,
        paddingRight: 8,
        min: minPowerMotor,
        max: maxPowerMotor,
        numberFormat: "#' W'"
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 11,
      location: 0.5
    });

    const powerMotorSeries = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: "Power Motor",
        xAxis: xAxis,
        stroke: am5.color("#0x4caf50"),
        yAxis: yAxis,
        valueYField: "powerMotor",
        valueXField: "distance",
        tooltip: am5.Tooltip.new(root, {
          labelText:
            "[bold]Power Motor:[/]\n[fontSize: 14px]{valueY} W\n[fontSize: 12px][#999999]Distance: {valueX} Km[/]"
        })
      })
    );

    powerMotorSeries.fills.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            opacity: 1,
            color: am5.color("#0x4caf50"),
            offset: 0.25
          },
          {
            opacity: 0,
            color: am5.color("#0x4caf50"),
            offset: 1
          }
        ],
        rotation: 90
      })
    );

    powerMotorSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 1
    });

    powerMotorSeries.data.setAll(
      data.map((step) => ({
        powerMotor: step.motorPower ? Number(step.motorPower) : 0,
        distance: step.distance
      }))
    );

    powerMotorSeries.appear(1000);
    chart.appear(1000, 100);

    // Cleanup on unmount
    return () => {
      root.dispose();
    };
  }, [data, theme, urlMode]);

  return <div className={cn("h-[300px]", className)} ref={chartRef} />;
};

export default ChartPowerMotorDistance;
