import DefaultVehicle from "../../../../assets/images/defaultVehicle.png";
import { Card } from "../../../../components/ui/card";
import { cn } from "../../../../lib/utils";
import { ProductsDetailsDataResponseModels } from "../../../Models/API/ProductsDataResponseModels";
import { t } from "i18next";

export interface ProductProps {
  className?: string;
  onClick?: () => void;
  product: ProductsDetailsDataResponseModels;
  defaultOpen?: boolean;
}

const Product = (props: ProductProps) => {
  return (
    <Card
      className={cn("p-2 flex flex-row sm:flex-row relative justify-between")}
    >
      {/* Product Info Section */}
      <div className='flex flex-row items-center mb-2 sm:mb-0'>
        <img
          src={
            props.product.vehicle.image
              ? props.product.vehicle.image.uri
              : DefaultVehicle
          }
          alt={props.product.vehicle.name}
          className='w-10 h-10 rounded-full object-cover bg-white'
        />

        <div className='flex flex-col ml-2'>
          <h2 className='text-sm font-bold'>{props.product.vehicle.name}</h2>
          <p className='text-xs'>{props.product.vehicle.brandName}</p>
          <p className='text-xs'>{props.product.vehicle.modelName}</p>
        </div>
      </div>

      {/* Session Details Section */}
      {props.product.currentSessionDetails && (
        <div className='flex flex-row items-center gap-4 p-1'>
          {/* Distance Section */}
          <div className='flex flex-col items-start text-muted-foreground'>
            <label className='text-xs font-semibold'>{t("Distance")}</label>
            <div className='text-sm font-bold'>
              {props.product.currentSessionDetails.totalDistance.toFixed(2) +
                " km"}
            </div>
          </div>

          {/* Duration Section */}
          <div className='flex flex-col items-start text-muted-foreground'>
            <label className='text-xs font-semibold'>{t("Duration")}</label>
            <div className='text-sm font-bold'>
              {`${
                props.product.currentSessionDetails.totalDuration.split(":")[0]
              }h ${
                props.product.currentSessionDetails.totalDuration.split(":")[1]
              }min`}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default Product;
