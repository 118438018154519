import { FC, ReactElement, useEffect, useState } from "react";
import "../../globals.css";
import "leaflet/dist/leaflet.css";
import "../HomePage/components/PrincipalPage.css";
import { Session } from "../../Models/API/ProductsDataResponseModels";
import ProductServices from "../../services/ProductServices";
import { useNavigate } from "react-router-dom";
import Sessions from "./components/Sessions";
import { timeout } from "../../../lib/utils";
import Products from "./components/Products";
import SessionDetails from "./components/SessionDetails";
import { setSessions, setVehicles, useCacheStore } from "../../services";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { SidebarProvider } from "../../../components/ui/sidebar";
import { AppSidebar } from "../../../components/custom/app-sidebar";
import { t } from "i18next";

export interface MainProps {}

const PrincipalPage: FC<MainProps> = (): ReactElement => {
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const navigate = useNavigate();
  const store = useCacheStore();

  useEffect(() => {
    document.title = "Zehus Portal | Sessions";
    const loadData = async () => {
      try {
        await timeout(500);

        let products = store.vehicles;
        if (products == null) {
          const productsList = await ProductServices.productsMyBike();
          const productsPromises = productsList.map((product) =>
            ProductServices.detailsProductsMyBike(product.id)
          );
          products = await Promise.all(productsPromises);

          for (const product of products) {
            if (product.currentSession?.sessionId) {
              try {
                product.currentSessionDetails =
                  await ProductServices.detailsProductsSessionMyBike(
                    product.hubId,
                    product.currentSession.sessionId
                  );
              } catch (error) {
                console.log("Failed to load current session", error);
              }
            }
          }
          setVehicles(products);
        }

        const sessions = products
          .flatMap((x) => x.sessions.map((y) => ({ ...y, hub: x })))
          .sort((x, y) => (x.startDate < y.startDate ? 1 : -1));
        setSessions(sessions);
        setSelectedSession(sessions[0]);
      } catch (err) {
        console.error(err);
        //setErrorProducts("Failed to load product data.");
      }
    };

    loadData();
  }, []);

  return (
    <>
      {/* mobile */}
      <ScrollArea className='flex flex-row lg:hidden p-0 overflow-y-auto m-2'>
        <Products className='flex xl:hidden gap-2 m-2' />
        <Sessions
          selected={selectedSession}
          className='gap-4 py-4 m-2'
          onItemClick={(s) =>
            navigate("/sessions/" + s.hub?.hubId + "/" + s.sessionId)
          }
        />
      </ScrollArea>

      {/* Desktop */}
      <div className='hidden lg:grid grid-cols-12'>
        <SidebarProvider
          open={true}
          className='col-span-3 xl:col-span-3 p-2 pr-4 gap-2 mt-4'
        >
          <AppSidebar>
            <Products className='flex xl:hidden gap-2' />
            <Sessions
              className='gap-2'
              selected={selectedSession}
              onItemClick={(s) => setSelectedSession(s)}
            />
          </AppSidebar>
        </SidebarProvider>

        <div className='overflow-y-auto grid grid-cols-8 xl:grid-cols-9 col-span-9 xl:col-span-9'>
          <div className='col-span-8 xl:col-span-6'>
            <div className={"p-2 flex flex-col gap-4"}>
              <SessionDetails
                hubId={selectedSession?.hub?.hubId}
                sessionId={selectedSession?.sessionId}
                myBikeName={selectedSession?.hub?.vehicle.name}
              />
            </div>
          </div>
          <div className='hidden xl:block xl:col-span-3'>
            <div className='sticky top-0 p-2'>
              <h1 className='text-green-500'>{t("MyVehicles")}</h1>
              <Products className='mt-2 gap-2' defaultOpen={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalPage;
