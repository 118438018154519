import { useMsal } from "@azure/msal-react";
import { Button } from "../../../components/ui/button";
import { useCallback } from "react";
import { t } from "i18next";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = useCallback(() => {
    //Clear
    localStorage.clear();
    sessionStorage.clear();

    instance.logoutRedirect();
  }, [instance]);

  return (
    <div className='w-full' title={t("LogOut")}>
      <Button
        type='button'
        className='w-full'
        variant='ghost'
        onClick={() => handleLogout()}
      >
        {t("LogOut")}
      </Button>
    </div>
  );
};
