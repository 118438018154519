import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "./dropdown-menu";
import { t } from "i18next";
import { Button } from "./button";

const languages = [
  { code: "en", label: "EN" },
  { code: "it", label: "IT" }
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlLang = searchParams.get("lang");
    if (urlLang && languages.some((lang) => lang.code === urlLang)) {
      changeLanguage(urlLang);
    }
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className='flex flex-row w-full bg-transparent border-0'
          variant='outline'
          size='icon'
          title={t("ChangeLanguage")}
        >
          <span className='m-2'>{t("Language")}</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align='end'>
        {languages.map(({ code, label }) => (
          <DropdownMenuItem
            key={code}
            onSelect={() => changeLanguage(code)}
            aria-pressed={currentLanguage === code}
          >
            <span>{label}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSwitcher;
