import { FC, ReactElement, useEffect, useState } from "react";
import {
  Bell,
  MapIcon,
  Menu,
  // BarChart2Icon,
  UserCircle
} from "lucide-react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import { ModeToggle } from "../../ui/mode-toggle";
import { SignOutButton } from "../../../app/pages/Login/SignOutButton";
import { SignInButton } from "../../../app/pages/Login/SignInButton";
import LanguageSwitcher from "../../ui/language-toogle";
import "./menuBarMobile.css";
import Logo from "../../../assets/images/logo.svg";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader
} from "../../ui/sheet";
import { cn } from "../../../lib/utils";
import MenuBarItem from "../menubar/MenuBarItem";

export interface MenuBarMobileProps {
  className?: string;
}

const MenuBarMobile: FC<MenuBarMobileProps> = (
  props: MenuBarMobileProps
): ReactElement => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const username = accounts.length > 0 ? accounts[0].name : "";
  const [isNotificato, setIsNotificato] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (isSheetOpen) {
        setIsSheetOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSheetOpen]);

  return (
    <>
      <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
        <SheetContent
          side={"left"}
          className={`mt-[4rem] gap-0 bg-menu p-0
            }`}
        >
          <SheetHeader>
            <SheetDescription></SheetDescription>
          </SheetHeader>
          {/* Menu laterale */}
          <MenuBarItem
            onItemClicked={() => {
              setIsSheetOpen(false);
            }}
            to='/sessions'
            isActive={false}
            isMenuOpen={true}
            textKey='Sessions'
          >
            <div className='sidebar-button-icon relative'>
              <MapIcon />
              {/* <div className='absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full animate-ping'></div> */}
            </div>
          </MenuBarItem>
        </SheetContent>
      </Sheet>

      <div
        className={cn(
          "bg-titlebar items-center justify-between shadow-md p-3",
          props.className
        )}
      >
        <Button
          variant='ghost'
          className='bg-topbar border-0 fs-2 cursor-pointer'
          onClick={() => setIsSheetOpen(!isSheetOpen)}
        >
          <Menu className='text-white' />
        </Button>

        {/* Logo Zehus */}
        <div className='menu-button-icon'>
          <div className='menu-logo-mobile'>
            <img src={Logo} alt='Logo' />
          </div>
        </div>

        <div className='flex flex-row justify-center items-center'>
          <Button
            className='flex justify-center text-white items-center rounded relative'
            title='Account'
            variant='ghost'
            onClick={() => setIsNotificato(!isNotificato)} // Cambia lo stato al click
          >
            <Bell />
            {isNotificato && (
              <span className='absolute top-1 right-4 h-3 w-3 bg-green-500 rounded-full' />
            )}
          </Button>

          {/* Utente options */}
          <div className='bg-topbar pe-2'>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className='menu-button' variant='secondary'>
                  <div className='menu-button-icon px-2'>
                    <UserCircle />
                  </div>
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent className='mt-4'>
                <DropdownMenuLabel>{username}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <ModeToggle />
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <LanguageSwitcher />
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuBarMobile;
