import { CalendarSearch, Filter, Star, X } from "lucide-react";
import { range } from "../../../../common/utils";

import "../../../globals.css";
import { Session } from "../../../Models/API/ProductsDataResponseModels";
import { CacheStoreState, useCacheStore } from "../../../services";
import SessionPreview from "./SessionPreview";
import SessionPreviewSkeleton from "./SessionPreviewSkeleton";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { Calendar } from "../../../../components/ui/calendar";
import { cn } from "../../../../lib/utils";

export interface SessionsProps {
  selected: Session | null;
  onItemClick?: (s: Session) => void;
  className?: string;
}

const Sessions = (props: SessionsProps) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const sessions = useCacheStore((state: CacheStoreState) => state.sessions);
  const { t } = useTranslation();
  const [isSortedRecent, setIsSortedRecent] = useState(true);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const getSessionsMonth = (sessions: Session): string => {
    const date = new Date(sessions.startDate);
    const month = date.toLocaleString("default", { month: "long" });
    const monthYear = `${
      month.charAt(0).toUpperCase() + month.slice(1)
    } ${date.getFullYear()}`;

    return monthYear;
  };

  const handleSort = () => {
    setIsSortedRecent((prev) => !prev);
  };

  const handleFavoriteToggle = () => {
    setIsFavorited((prev) => !prev);
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
    }, 400);
  };

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const sortedSessions = sessions
    ? [...sessions].sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return isSortedRecent
          ? dateB.getTime() - dateA.getTime()
          : dateA.getTime() - dateB.getTime();
      })
    : [];

  return (
    <div className={cn("flex flex-col", props.className)}>
      <div className='sticky top-0 m-2'>
        <div className='flex flex-row justify-between items-center'>
          <h1 className='text-green-500'>{t("Sessions")}</h1>
          <div className='flex flex-row'>
            <Button
              variant='ghost'
              className='p-2'
              title='Favorite'
              onClick={handleFavoriteToggle}
            >
              {isFavorited ? (
                <StarFilledIcon
                  className={`text-green-500 h-6 w-6 ${
                    isAnimating ? "favorite-animation" : ""
                  }`}
                />
              ) : (
                <Star
                  className={`text-green-500 h-6 w-6 ${
                    isAnimating ? "favorite-animation" : ""
                  }`}
                />
              )}
            </Button>
            <Button
              variant='ghost'
              className='p-2 relative text-foreground'
              title='Calendar'
              onClick={toggleCalendar}
            >
              <CalendarSearch className='text-green-500 h-6 w-6' />
            </Button>
            <Button
              variant='ghost'
              className='p-2'
              title='Order by'
              onClick={handleSort}
            >
              <Filter className='text-green-500 h-6 w-6' />
            </Button>
          </div>
        </div>
      </div>

      {isCalendarOpen && (
        <div className='fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50'>
          <div className='bg-black rounded-lg shadow-lg p-4'>
            <div className='flex justify-end'>
              {" "}
              <Button
                variant='ghost'
                onClick={toggleCalendar}
                className=' text-red-500'
              >
                <X />
              </Button>
            </div>
            <Calendar
              mode='single'
              selected={date}
              onSelect={setDate}
              className='rounded-md border'
            />
          </div>
        </div>
      )}

      {sessions == null &&
        range(0, 9).map((index) => (
          <SessionPreviewSkeleton key={"session_skeleton_" + index} />
        ))}

      {sortedSessions.length > 0 && (
        <>
          {sortedSessions.map((session, index) => {
            const month = getSessionsMonth(session);

            return (
              <>
                {(index === 0 ||
                  month !== getSessionsMonth(sortedSessions[index - 1])) && (
                  <div className='font-xs font-bold text-xl px-2'>{month}</div>
                )}
                <SessionPreview
                  key={"session_" + index}
                  session={session}
                  selected={session === props.selected}
                  onClick={() =>
                    props.onItemClick && props.onItemClick(session)
                  }
                />
              </>
            );
          })}
        </>
      )}

      <div className='flex justify-center items-center h-screen'>
        {sessions != null && sessions.length === 0 && (
          <p>{t("SessionNotFound")}</p>
        )}
      </div>
    </div>
  );
};

export default Sessions;
