import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/App.config";
import { Button } from "../../../components/ui/button";
import { t } from "i18next";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <div className='ml-auto' title='Sign In'>
      <Button type='button' onClick={() => handleLogin("redirect")}>
        {t("SignIn")}
      </Button>
    </div>
  );
};
