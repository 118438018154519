import { ProductsDetailsDataResponseModels, ProductsDetailsSessionDataResponseModels, Session } from "../../Models/API/ProductsDataResponseModels";
import { useCacheStore } from "./store";

export function setError(error: string) {
  useCacheStore.setState((prev) => ({
    ...prev,
    errors: [...prev.errors, error],
  }));
}

export function clearErrors() {
  useCacheStore.setState((prev) => ({
    ...prev,
    errors: [],
  }));
}

export function setPrompts(
  message: string,
  title?: string,
  confirmTextKey?: string
) {
  const promise: Promise<boolean> = new Promise(function (resolve) {
    useCacheStore.setState((prev) => ({
      ...prev,
      prompt: {
        title: title,
        message: message,
        confirmTextKey: confirmTextKey,
        callback: (accepted) => {
          clearPrompt();
          resolve(accepted);
        },
      },
    }));
  });

  return promise;
}

export function clearPrompt() {
  useCacheStore.setState((prev) => ({
    ...prev,
    prompt: null,
  }));
}

export function setVehicles(vehicles: ProductsDetailsDataResponseModels[]) {
  useCacheStore.setState((prev) => ({
    ...prev,
    vehicles: vehicles,
  }));
}

export function setSessions(sessions: Session[]) {
  useCacheStore.setState((prev) => ({
    ...prev,
    sessions: sessions,
  }));
}

export function cacheSession(session: ProductsDetailsSessionDataResponseModels) {
  useCacheStore.setState((prev) => ({
    ...prev,
    cache: [...prev.cache, session],
  }));
}

export function setWebView() {
  useCacheStore.setState((prev) => ({
    ...prev,
    isWebView: true,
  }));
}

export function setAccessToken(accessToken: string) {
  useCacheStore.setState((prev) => ({
    ...prev,
    accessToken: accessToken,
  }));
}