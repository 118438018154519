import { Card, CardContent } from "../../../../components/ui/card";
import { cn, timeout } from "../../../../lib/utils";
import { Session } from "../../../Models/API/ProductsDataResponseModels";
import { useTranslation } from "react-i18next";
import { Star } from "lucide-react";
import { format, isSameWeek } from "date-fns";
import MapRender from "../Map/Components/MapRender";
import { useEffect, useRef, useState } from "react";
import L from "leaflet";
import { Button } from "../../../../components/ui/button";

export interface SessionPreviewProps {
  session: Session;
  onClick: () => void;
  className?: string;
  selected: boolean;
}

function getDayOrDate(date: Date) {
  const today = new Date();
  const isCurrentWeek = isSameWeek(date, today, { weekStartsOn: 1 });

  return isCurrentWeek ? format(date, "EEEE") : date.toLocaleDateString();
}

const SessionPreview = (props: SessionPreviewProps) => {
  const mapRef = useRef<L.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const { t } = useTranslation();

  const sessions = [props.session];

  useEffect(() => {
    const initializeMap = async () => {
      if (
        mapRef.current &&
        props.session.gpsPreview &&
        props.session.gpsPreview.length > 0
      ) {
        mapRef.current.dragging.disable();
        mapRef.current.touchZoom.disable();
        mapRef.current.scrollWheelZoom.disable();
        mapRef.current.doubleClickZoom.disable();
        mapRef.current.boxZoom.disable();
        mapRef.current.keyboard.disable();
        mapRef.current.zoomControl?.remove();
        mapRef.current.attributionControl.remove();

        const gpsBounds: L.LatLngTuple[] = props.session.gpsPreview.map((x) => [
          x.latitude,
          x.longitude
        ]);

        mapRef.current.fitBounds(gpsBounds, {
          animate: false,
          duration: 0,
          padding: [0, 0]
        });

        await timeout(250);
        setMapLoaded(true);
      }
    };

    initializeMap();
  }, [mapRef, props.session.gpsPreview]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
    setMapLoaded(true);
  }, [props.session.gpsPreview]);

  return (
    <div className={cn("relative cursor-pointer group")}>
      {sessions.map((session) => (
        <Card
          key={session.sessionId}
          className={cn(
            "card-overlay p-0 shadow-lg border-0 lg:rounded-lg animated lg:hover:border-r-4",
            props.selected && "lg:border-r-primary lg:border-r-4",
            props.className
          )}
          onClick={props.onClick}
        >
          <CardContent className={cn("p-0 flex flex-col relative")}>
            <div className='flex flex-row items-start justify-between'>
              <div className='flex flex-row'>
                <div className='m-2'>
                  {session.gpsPreview && session.gpsPreview.length > 0 ? (
                    <MapRender
                      showMarkers={false}
                      className={cn(
                        "h-[50px] w-[50px] pointer-events-none animated rounded-lg overflow-hidden",
                        mapLoaded ? "opacity-100" : "opacity-0"
                      )}
                      mapRef={mapRef}
                      disableDragging={true}
                      attributionControl={false}
                      locations={session.gpsPreview}
                      loading={false}
                      center={
                        new L.LatLng(
                          session.gpsPreview[0].latitude,
                          session.gpsPreview[0].longitude
                        )
                      }
                      zoom={undefined}
                      showZoomControl={false}
                    />
                  ) : (
                    <div className='h-[50px] w-[50px] flex justify-center items-center bg-menu'>
                      <div className='font-xs font-bold text-center'>
                        {t("PreviewNotAvailable")}
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex flex-col px-4 p-1'>
                  <div className='font-bold'>
                    {getDayOrDate(new Date(session.startDate))} -{" "}
                    {new Date(session.startDate).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit"
                    })}
                  </div>
                  <div>{session.name}</div>
                </div>
              </div>

              <div className='ml-2 flex flex-col'>
                <div className='flex flex-row items-start'>
                  <div className='flex justify-end'>
                    <Button variant='ghost' className='p-2' title='Favorite'>
                      <Star className='text-green-500' />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='grow grid grid-cols-3 gap-2 m-2'>
              <div>
                <label className='text-xs text-muted-foreground'>
                  {t("Distance")}
                </label>
                <div>{session.totalDistance?.toFixed(2)} Km</div>
              </div>
              <div>
                <label className='text-xs text-muted-foreground'>
                  {t("Duration")}
                </label>
                <div>{session.totalDuration}</div>
              </div>
              <div>
                <label className='text-xs text-muted-foreground'>
                  {t("AverageSpeed")}
                </label>
                <div>{session.avgSpeed + " km/h"}</div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default SessionPreview;
