import { FC, ReactElement } from "react";

interface Props {
  type: number;
  width: string;
  height: string;
}

const colors: string[] = [
  "#0f9d58",
  "#dbbd12",
  "#444746",
  "#2411cf",
  "#cf1212",
];

const Bike: FC<Props> = ({ type, width, height }: Props): ReactElement => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 335 460'
  >
    <path
      fill={colors[type]}
      d='M.34,175.26C-.61,86.55,59.81,15.14,139.94,2.3c90.29-14.47,174.31,44,191.67,133.5,7.73,39.85.69,77.14-19.55,112.32-22.08,38.37-47.46,74.55-72.44,111q-33.09,48.33-66.51,96.46c-3.89,5.62-7.45,5.76-11.18.29C121.39,396.44,80.67,337.11,40.48,277.42,27.13,257.59,15.34,236.76,7.41,214,2.15,198.83.3,183.27.34,175.26Z'
    />
    <path
      fill='#fff'
      d='M170.2,146.19l-19.48,20.14-.38-.18c.4.24.8.49,1.22.71,5.69,3,11.34,6,17.07,8.94,4.65,2.36,6.86,5.86,6.78,11.23-.2,13.48-.07,27-.06,40.45a9.93,9.93,0,0,1-1.43,5.7,8.63,8.63,0,0,1-15.84-4.39c-.09-2.94,0-5.89,0-8.84,0-8,0-16,0-24A1.8,1.8,0,0,0,157,194.1q-15.42-8.5-30.78-17.08c-7.92-4.41-9.28-13.93-2.89-20.35q15.66-15.75,31.38-31.44c5.46-5.45,14.82-4.07,19.22,2.81q7.78,12.15,15.52,24.34a2.22,2.22,0,0,0,2.19,1.18q11.51-.09,23,0a11,11,0,0,1,4,.72,5.18,5.18,0,0,1,3.32,5.23,5.24,5.24,0,0,1-3.43,5,9.31,9.31,0,0,1-3.37.69c-9.63.05-19.25,0-28.87,0-3,0-4.94-1.63-6.45-4l-8.64-13.52C170.94,147.29,170.66,146.88,170.2,146.19Z'
    />
    <path
      fill='#fff'
      d='M149.73,223a40.89,40.89,0,1,1-40.95-40.78A40.84,40.84,0,0,1,149.73,223Zm-40.88,29.73a29.67,29.67,0,1,0-29.72-29.61A29.73,29.73,0,0,0,108.85,252.73Z'
    />
    <path
      fill='#fff'
      d='M185.31,223.07a40.87,40.87,0,1,1,41,40.79A40.9,40.9,0,0,1,185.31,223.07Zm11.14,0a29.72,29.72,0,1,0,29.68-29.7A29.68,29.68,0,0,0,196.45,223.08Z'
    />
    <path
      fill='#fff'
      d='M170.67,114a15,15,0,1,1,15.11,14.91A14.93,14.93,0,0,1,170.67,114Z'
    />
  </svg>
);

export default Bike;
