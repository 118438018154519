import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu
} from "../ui/sidebar";

interface AppSidebarProps {
  children?: React.ReactNode;
}

export function AppSidebar(props: AppSidebarProps) {
  return (
    <Sidebar className='w-[25%]'>
      <SidebarContent className='bg-black'>
        <SidebarGroup>
          <SidebarGroupLabel></SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>{props.children}</SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
