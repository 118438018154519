import { FC, ReactElement, useEffect } from "react";

const NotFoundPage: FC = (): ReactElement => {

  useEffect(() => {
    document.title = 'Zehus - Not found';
  }, []);

  return (
    <div className='content-page'>
      <h1>404</h1>
      <p>You don't want to be here</p>
    </div>
  );
};

export default NotFoundPage;
