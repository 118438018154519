import axios from "axios";
import { environment } from "../config/App.config";
import { setError } from "../app/services";
import { IPublicClientApplication } from "@azure/msal-browser";
import { sendWebViewMessage } from "./webview";

export function addAxiosBearerToken(token: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// Axios serialize arrays in query string as key[]=value1&key[]=value2&key[]=value3
// but our .Net Backend requires key=value1&key=value2&key=value3
export function addAxiosCustomSerializer() {
  axios.defaults.paramsSerializer = function customSerializer(params) {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${encodeURIComponent(v)}`).join("&");
        } else {
          return `${key}=${encodeURIComponent(value)}`;
        }
      })
      .join("&");
  };
}

export function addAxiosInterceptors(instance: IPublicClientApplication, isWebView: boolean) {

  axios.interceptors.request.clear();
  axios.interceptors.request.use(
    function (request) {

      if (environment.isDevelopment) {
        console.log(">>> Request", request)
      }

      return request;
    }
  )

  axios.interceptors.response.clear();
  axios.interceptors.response.use(
    function (response) {
      // Status code that lie within the range of 2xx
      if (environment.isDevelopment) {
        console.log(">>> Response", response);
      }

      return response;
    },
    function (error) {
      // Status codes that falls outside the range of 2xx
      if (environment.isDevelopment) {
        console.error("Axios response interceptor - " + error.message, JSON.stringify(error));
      }

      if (axios.isAxiosError(error)) {
        // Example handling for network-related errors
        if (error.code === "ERR_NETWORK") {
          setError("Connection problem, please retry");
        } else if (error.code === "ERR_CANCELED") {
          setError("Connection canceled");
        }

        if (error.response?.status === 401) {
          if (isWebView) {
            sendWebViewMessage("TokenExpired");
          } else {
            instance.loginRedirect();
          }
          return Promise.reject("Unauthenticated");
        }

        // Handle specific HTTP status codes
        if (error.response?.data?.error) {
          // do not show dialog in permissions request
          if (
            !error.response?.request?.responseURL?.endsWith(
              "/User/Functionalities"
            )
          ) {
            setError(error.response.data.error);
          }
          return Promise.reject(error.response.data.error);
        }

        setError(error.message);

        return Promise.reject(error.message);
      }
    }
  );
}
