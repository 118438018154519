import {
  ProductsDataResponseModels,
  ProductsDetailsDataResponseModels,
  ProductsDetailsSessionDataResponseModels
} from "../Models/API/ProductsDataResponseModels";
import axios from "./baseServices";
import { productsDetailsUrl, productsUrl } from "./Service.config";

const ProductServices = {
  //** Get Product MyBike*/
  productsMyBike: async () => {
    const response = await axios.get<ProductsDataResponseModels>(
      `${productsUrl}`,
      {
        params: {
          productType: ["aio", "shm"]
        }
      }
    );
    return response.data.data;
  },

  //** Get Detail Product Hub MyBike*/
  detailsProductsMyBike: async (hubId: string) => {
    const response = await axios.get<ProductsDetailsDataResponseModels>(
      `${productsDetailsUrl}/${hubId}`
    );
    return response.data;
  },

  //** Get Detail Product Session Hub MyBike*/
  detailsProductsSessionMyBike: async (hubId: string, sessionId: string) => {
    const response = await axios.get<ProductsDetailsSessionDataResponseModels>(
      `${productsDetailsUrl}/${hubId}/session/${sessionId}`
    );
    return response.data;
  },

  //*Delete Session Bike
  deleteSessionBike: async (hubId: string, sessionId: string) => {
    const deleteResponse =
      await axios.delete<ProductsDetailsSessionDataResponseModels>(
        `${productsDetailsUrl}/${hubId}/session/${sessionId}`
      );
    const operationId = deleteResponse.data as unknown as {
      operationId: number;
    };
    console.log(operationId);
    return await ProductServices.detailsProductsMyBike(hubId);
  },

  updateSessionTelemetry: async (
    hubId: string,
    sessionId: string,
    title: string
  ) => {
    await axios.patch(`${productsDetailsUrl}/${hubId}/session/${sessionId}`, {
      newName: title
    });
    return await ProductServices.detailsProductsMyBike(hubId);
  }
};

export default ProductServices;
