import { memo, ReactElement, useEffect, useMemo, useRef } from "react";
import ReactDomServer from "react-dom/server";
import { FeatureGroup, MapContainer, Marker, TileLayer } from "react-leaflet";
import Spinner from "../../../../../components/custom/spinner/Spinner";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import * as L from "leaflet";
import "../Components/Map.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import {
  GpsInfo,
  SessionTelemetrySteps
} from "../../../../Models/API/ProductsDataResponseModels";
import Bike from "../../../../../assets/icon/Bike";
import { Polyline } from "react-leaflet";
import FinishIcon from "../../../../../assets/icon/FinishIcon.png";
import { Scan } from "lucide-react";
import { Button } from "../../../../../components/ui/button";
import { useTranslation } from "react-i18next";
import { cn } from "../../../../../lib/utils";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

const DefaultIcon = new L.DivIcon({
  html: ReactDomServer.renderToString(<Bike type={0} width='25' height='35' />),
  iconSize: [35, 35],
  iconAnchor: [12, 35],
  popupAnchor: [2, -40],
  className: "bg-transparent"
});
L.Marker.prototype.options.icon = DefaultIcon;

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "leaflet-gesture-handling";
declare module "leaflet" {
  interface Map {
    gestureHandling: {
      enable: () => void;
      disable: () => void;
    };
  }
}

const FlagIconMarker = new L.DivIcon({
  html: ReactDomServer.renderToString(
    <div
      style={{
        width: "35px",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <img
        src={FinishIcon}
        alt='Finish Flag Icon Session'
        style={{ width: "40px", height: "40px" }}
      />
    </div>
  ),
  iconSize: [30, 30],
  iconAnchor: [17, 34],
  popupAnchor: [0, -33],
  className: "bg-transparent"
});

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right"
};

const getColorBasedOnSpeed = (TelemetrySteps: SessionTelemetrySteps) => {
  if (TelemetrySteps.speed < 10) return "#00FF00"; // Green for slow speeds
  if (TelemetrySteps.speed < 20) return "#FFFF00"; // Yellow for moderate speeds
  if (TelemetrySteps.speed < 25) return "#FFA500"; // Orange for faster speeds
  return "#FF0000"; // Red for high speeds
};
// const getAnimationClass = (step: SessionTelemetrySteps) => {
//   if (step.speed < 10) return "anim-duration-25s"; // Velocità bassa
//   if (step.speed < 20) return "anim-duration-20s"; // Velocità moderata
//   if (step.speed < 25) return "anim-duration-15s"; // Velocità alta
//   return "anim-duration-10s"; // Velocità massima
// };
export interface MapControlProps {
  mapRef?: React.RefObject<L.Map>;
  locations: GpsInfo[];
  center: L.LatLngExpression | undefined;
  zoom: number | undefined;
  showMiniMap?: boolean;
  minimapPosition?: keyof typeof POSITION_CLASSES;
  telemetryData?: SessionTelemetrySteps[] | null;
  showZoomControl?: boolean;
  disableDragging?: boolean;
  showMarkers?: boolean;
  centerMapOnLastMarker?: () => void;
  attributionControl?: boolean;
}
export interface MapRenderProps extends MapControlProps {
  loading: boolean;
  className?: string;
}

const MapControl = memo((props: MapControlProps) => {
  const polylinePositions = useMemo(
    () =>
      props.locations.map(
        (step) => [step.latitude, step.longitude] as [number, number]
      ),
    [props.locations]
  );

  const firstLocationMarker = useMemo(() => {
    if (props.locations.length === 0) return null;

    const firstStep = props.locations[0];
    if (!firstStep) return null;

    const { latitude, longitude } = firstStep;

    return (
      <Marker position={[latitude, longitude]} icon={DefaultIcon}></Marker>
    );
  }, [props.locations]);

  const lastLocationMarker = useMemo(() => {
    if (props.locations.length === 0) return null;

    const lastStep = props.locations[props.locations.length - 1];
    if (!lastStep) return null;

    const { latitude, longitude } = lastStep;
    return (
      <Marker position={[latitude, longitude]} icon={FlagIconMarker}></Marker>
    );
  }, [props.locations]);

  const buttonAddedRef = useRef(false);
  const telemetryData = props.telemetryData ?? [];

  useEffect(() => {
    if (props.mapRef?.current && !buttonAddedRef.current) {
      const map = props.mapRef.current;
      if (map) {
        map.gestureHandling.enable();
      }

      const CenterControl = L.Control.extend({
        options: {
          position: "topright",
          gestureHandling: true,
          gestureHandlingOptions: {
            text: {
              touch: "Use two fingers to move the map",
              scroll: "Use Ctrl + scroll to zoom",
              scrollMac: "Use Command + scroll to zoom"
            }
          }
        },

        onAdd: function () {
          const button = L.DomUtil.create(
            "button",
            "leaflet-bar leaflet-control leaflet-control-custom"
          );
          button.innerHTML = ReactDomServer.renderToString(
            props.showMarkers ? (
              <Button
                title='Map Center'
                about='Map Center'
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                  transition: "background-color 0.3s, border-color 0.3s"
                }}
                variant='ghost'
              >
                <Scan style={{ color: "black" }} />
              </Button>
            ) : (
              <></>
            )
          );

          L.DomEvent.on(button, "click", () => {
            if (props.centerMapOnLastMarker) {
              props.centerMapOnLastMarker();
            }
          });

          return button;
        }
      });

      const controlInstance = new CenterControl();
      map.addControl(controlInstance);
      buttonAddedRef.current = true;
    }
  }, [props.mapRef, props.centerMapOnLastMarker]);

  return (
    <MapContainer
      center={props.center || [51.505, -0.09]}
      zoom={props.zoom || 12}
      style={{ width: "100%", height: "100%" }}
      scrollWheelZoom={false}
      worldCopyJump={true}
      touchZoom={true}
      dragging={props.disableDragging ?? true}
      ref={props.mapRef}
      zoomControl={props.showZoomControl ?? true}
      attributionControl={props.attributionControl ?? false}
    >
      <TileLayer
        attribution='Zehus App Mobile'
        url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <FeatureGroup>
        <>
          {telemetryData.length === 0 ? (
            <Polyline
              positions={polylinePositions}
              className={cn("animated-polyline", "anim-duration-10s")}
              pathOptions={{
                color: "hsl(var(--primary))",
                weight: 4,
                opacity: 1
              }}
            />
          ) : (
            telemetryData.length > 1 && (
              <div className='group'>
                {telemetryData.map((step, index) => {
                  if (
                    index === 0 ||
                    !polylinePositions[index - 1] ||
                    !polylinePositions[index]
                  ) {
                    return null;
                  }

                  const previousPosition = polylinePositions[index - 1];
                  const currentPosition = polylinePositions[index];
                  const color = getColorBasedOnSpeed(step);

                  return (
                    <Polyline
                      key={index}
                      positions={[previousPosition, currentPosition]}
                      // className={cn(
                      //   "animated-polyline",
                      //   getAnimationClass(step)
                      // )}
                      pathOptions={{
                        color: color,
                        weight: 4,
                        opacity: 1
                      }}
                    />
                  );
                })}
              </div>
            )
          )}
          {props.showMarkers ? (
            <>
              {firstLocationMarker}
              {lastLocationMarker}
            </>
          ) : (
            <></>
          )}
        </>
      </FeatureGroup>
    </MapContainer>
  );
});

const MapRender = (props: MapRenderProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={props.className}>
      <MapControl
        mapRef={props.mapRef}
        locations={props.locations}
        center={props.center}
        zoom={props.zoom}
        showMiniMap={props.showMiniMap}
        minimapPosition={props.minimapPosition}
        showZoomControl={props.showZoomControl}
        disableDragging={props.disableDragging}
        centerMapOnLastMarker={props.centerMapOnLastMarker}
        telemetryData={props.telemetryData}
        showMarkers={props.showMarkers}
      />

      {props.loading && (
        <>
          <div
            style={{ zIndex: 2 }}
            className='absolute top-0 bottom-0 left-0 right-0 bg-black opacity-60'
          />
          <div
            style={{ zIndex: 2 }}
            className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center'
          >
            <Spinner forceDark={true} width={52} height={52} />
            <p className='text-white text-sm ml-5'> {t("Loading")}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default MapRender;
