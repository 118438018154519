import { FC, ReactElement } from "react";
import "../../../globals.css";
import "leaflet/dist/leaflet.css";
import "../../../globals.css";
import "../../HomePage/components/PrincipalPage.css";
import { useParams } from "react-router-dom";
import SessionDetails from "../components/SessionDetails";

export interface MapProps {}

const PageMap: FC<MapProps> = (): ReactElement => {
  const { sessionId, hubId } = useParams();

  return (
    <div className='overflow-y-auto scrollbar-gutter-stable m-5'>
      <SessionDetails sessionId={sessionId} hubId={hubId} />
    </div>
  );
};

export default PageMap;
