import "./menuBar.css";
import { Link } from "react-router-dom";
import { FC, ReactElement } from "react";
import { buttonVariants } from "../../ui/button";
// import { useTranslation } from "react-i18next";
// import { AuthView } from "../../auth/AuthView";
import { t } from "i18next";

export interface MenuBarItemProps {
  claims?: string[] | Array<string[]>;
  onItemClicked?: () => void;
  isMenuOpen: boolean;
  to: string;
  isActive: boolean;
  children: React.ReactNode;
  textKey: string;
}

const MenuBarItem: FC<MenuBarItemProps> = (
  props: MenuBarItemProps
): ReactElement => {
  return (
    <Link
      onClick={props.onItemClicked}
      to={props.to}
      className={`${buttonVariants()} sidebar-button py-2 h-auto px-0 ps-2 pe-2 sm:ps-0 sm:pe-2 sm:mb-1 sm:py-0 m-0 rounded-none ${props.isActive ? "active" : ""
        }`}
    >
      <div className='sidebar-button-icon'>{props.children}</div>
      <div
        className={
          "sidebar-button-label " + (props.isMenuOpen ? "opened" : "closed")
        }
      >
        {t(props.textKey)}
      </div>
    </Link>
  );
};

export default MenuBarItem;
