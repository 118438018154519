import { Card, CardContent, CardHeader } from "../../../../components/ui/card";
import { Skeleton } from "../../../../components/ui/skeleton";
import { cn } from "../../../../lib/utils";

export interface SessionPreviewSkeletonProps {
  className?: string
}

const SessionPreviewSkeleton = (props: SessionPreviewSkeletonProps) => {

  return (
    <Card className={cn(
      "card-overlay p-0 border-0 shadow-lg rounded-none lg:rounded-lg pointer-events-none",
      props.className
    )}>
      <CardHeader className="p-0 lg:hidden">
        <div className='flex items-center p-2'>
          <Skeleton className='mx-2 my-1 w-12 h-12 rounded-full' />
          <div className='grow flex flex-col items-start p-2'>
            <Skeleton className="mt-1 h-3 w-[80px] rounded-full" />
            <Skeleton className="mt-2 h-2 w-[140px] rounded-full" />
            <Skeleton className="mt-2 h-2 w-[120px] rounded-full" />
          </div>
          <Skeleton className="text-end mt-1 h-3 w-[80px] rounded-full" />
        </div>
      </CardHeader>
      <CardContent className={cn("p-0 flex flex-col sm:flex-row")}>
        <Skeleton className={cn("h-[300px] w-full sm:h-[100px] sm:w-[100px] rounded-none")} />
        <div className='grow grid grid-cols-2 gap-2 p-3'>
          <div className="col-span-2 flex items-center justify-between">
            <Skeleton className="h-[16px] w-[80px] rounded-full" />
            <Skeleton className="hidden lg:block h-[16px] w-[80px] rounded-full" />
          </div>
          <div className="block mt-4">
            <Skeleton className="h-[12px] w-[40px] mb-1 rounded-full" />
            <Skeleton className="h-[16px] w-[80px] rounded-full" />
          </div>
          <div className="block mt-4">
            <Skeleton className="h-[12px] w-[40px] mb-1 rounded-full" />
            <Skeleton className="h-[16px] w-[80px] rounded-full" />
          </div>
          <div className="block sm:hidden mt-4">
            <Skeleton className="h-[12px] w-[40px] mb-1 rounded-full" />
            <Skeleton className="h-[16px] w-[80px] rounded-full" />
          </div>
          <div className="block sm:hidden mt-4">
            <Skeleton className="h-[12px] w-[40px] mb-1 rounded-full" />
            <Skeleton className="h-[16px] w-[80px] rounded-full" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SessionPreviewSkeleton;
