import { FC, ReactElement } from "react";

interface SpinnerSvgProps {
  width?: number;
  height?: number;
}

const SpinnerSvg: FC<SpinnerSvgProps> = ({ width, height }: SpinnerSvgProps): ReactElement => (

  <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 48} height={height ?? 48} viewBox="0 0 64 64">
    <g data-name="Tracciato 40" transform="translate(-163 -106)">
      <rect data-name="Rettangolo 1346" width="64" height="64" rx="32" transform="translate(163 106)" fill="none" />
      <path data-name="Tracciato 40" fill="none" d="M59.254 26.194a4.7 4.7 0 0 1 1.052-2.357 30.507 30.507 0 0 0-7.821-14.5 18.8 18.8 0 0 1-4.695-2.218 4.509 4.509 0 0 1-.939-2.348A30.329 30.329 0 0 0 31.068 0a18 18 0 0 1-4.835 1.719A4.694 4.694 0 0 1 23.914.7 30.483 30.483 0 0 0 9.389 8.5a19.316 19.316 0 0 1-2.216 4.7 4.6 4.6 0 0 1-2.385.892A30.342 30.342 0 0 0 0 29.81c.732 1.559 1.756 3.916 1.765 4.94A4.772 4.772 0 0 1 .7 37.135a30.4 30.4 0 0 0 7.68 14.426c1.624.582 4.075 1.54 4.826 2.263a4.828 4.828 0 0 1 .939 2.479 30.31 30.31 0 0 0 15.6 4.7c1.549-.733 4-1.8 5.042-1.822a4.825 4.825 0 0 1 2.432 1.1 30.408 30.408 0 0 0 14.365-7.683c.592-1.625 1.549-4.085 2.272-4.827a4.759 4.759 0 0 1 2.451-.939A30.333 30.333 0 0 0 61 31.125c-.7-1.541-1.728-3.889-1.746-4.931z" transform="translate(164.5 107.5)" />
    </g>
  </svg>
);

export default SpinnerSvg;
