import base64js from "base64-js";

export async function convertBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      if (fileReader.result != null && typeof fileReader.result === "string") {
        resolve(fileReader.result.split(",")[1]);
      }
    };
    fileReader.onerror = (error) => reject(error);
  });
}

export function distinct<T>(array: Array<T>) {
  return array.filter((value, index, array) => array.indexOf(value) === index);
}

export function intersect<T>(
  array1: Array<T>,
  array2: Array<T>,
  key: (v: T) => object
) {
  array1.filter((x) => array2.map((x) => key(x)).find((y) => y === key(x)));
}

export async function toBase64(file: File) {
  const arrayBuffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  return base64js.fromByteArray(uint8Array);
}

export async function bufferToBase64(arrayBuffer: ArrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  return base64js.fromByteArray(uint8Array);
}

export function range(from: number, to: number, step?: number) {
  return Array.from(
    { length: (to - from) / (step ?? 1) + 1 },
    (_, index) => from + index * (step ?? 1)
  );
}

export function calculateDateNMonthsAgo(months: number) {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - months);
  return currentDate.toISOString();
}

export function format(num: number, decimals?: number): string | undefined {
  return num?.toLocaleString("it-it", {
    minimumFractionDigits: decimals ?? 2,
    maximumFractionDigits: decimals ?? 2
  });
}

export function pointInPolygon(point: L.LatLng, polygon: L.Polygon) {
  let inside = false;

  const polyLatLngs = polygon.getLatLngs()[0] as L.LatLng[];

  if (polyLatLngs && polyLatLngs.length > 0) {
    let j = polyLatLngs.length - 1;
    for (let i = 0; i < polyLatLngs.length; j = i++) {
      const xi = polyLatLngs[i].lat;
      const yi = polyLatLngs[i].lng;
      const xj = polyLatLngs[j].lat;
      const yj = polyLatLngs[j].lng;

      const intersect =
        yi > point.lng !== yj > point.lng &&
        point.lat < ((xj - xi) * (point.lng - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
  }
  return inside;
}
