import React from "react";
import "./spinner.css";
import SpinnerSvg from "../../../assets/icon/SpinnerSvg";

interface SpinnerProps {
  forceDark?: boolean;
  width?: number;
  height?: number;


}
const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {

  const width = props.width ?? 64;
  const height = props.height ?? 64;

  return (
    <div className={props.forceDark ? "circle-container-white" : "circle-container"} style={{ width: width, height: height }}>
      <SpinnerSvg width={width} height={height} />
    </div>
  );
};

export default Spinner;
