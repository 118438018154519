import * as am5radar from "@amcharts/amcharts5/radar";
import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { useTheme } from "../../../../components/ui/theme-provider";
import { SessionTelemetrySteps } from "../../../Models/API/ProductsDataResponseModels";

export interface ChartPowerModeKmProps {
  data: SessionTelemetrySteps[];
  className?: string;
}

const ChartPowerModeKm = (props: ChartPowerModeKmProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();

  const searchParams = new URLSearchParams(window.location.search);
  const urlMode = searchParams.get("mode");

  const distanceByPowerMode = props.data.reduce((acc, step) => {
    if (!acc[step.powerMode]) {
      acc[step.powerMode] = 0;
    }
    acc[step.powerMode] += step.distance;
    return acc;
  }, {} as Record<string, number>);

  const powerModeData = Object.entries(distanceByPowerMode).map(
    ([powerMode, distance]) => ({
      powerMode,
      distance
    })
  );

  const totalDistance = powerModeData.reduce(
    (acc, item) => acc + item.distance,
    0
  );

  useEffect(() => {
    if (!chartRef.current) return;

    const root = am5.Root.new(chartRef.current);

    const am5themes: am5.Theme[] = [am5themes_Animated.new(root)];

    if (urlMode === "dark" || urlMode === null) {
      am5themes.push(am5themes_Dark.new(root));
    }

    root.setThemes(am5themes);

    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 160,
        endAngle: 380
      })
    );

    const axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -40
    });

    axisRenderer.grid.template.setAll({
      stroke: root.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: totalDistance,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    const axisDataItem = xAxis.makeDataItem({});
    const clockHand = am5radar.ClockHand.new(root, {
      pinRadius: am5.percent(0),
      radius: am5.percent(120),
      bottomWidth: 40
    });

    const bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(axisDataItem);

    const label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        fill: am5.color(0xffffff),
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em"
      })
    );

    axisDataItem.set("value", 50);

    bullet.get("sprite").on("rotation", function () {
      const value = axisDataItem.get("value") ?? 0;
      let fill = am5.color(0x000000);
      let powerModeText = "";
      let distanceKm = 0;

      xAxis.axisRanges.each(function (axisRange) {
        const axisStartValue = axisRange.get("value");
        const axisEndValue = axisRange.get("endValue");

        if (axisStartValue !== undefined && axisEndValue !== undefined) {
          if (value >= axisStartValue && value <= axisEndValue) {
            fill =
              axisRange.get("axisFill")?.get("fill") ?? am5.color(0x000000);
            powerModeText = axisRange.get("label")?.get("text") ?? "";
            distanceKm = axisEndValue - axisStartValue;
          }
        }
      });

      label.set("text", `${powerModeText}: ${distanceKm.toFixed(2)} km`);

      clockHand.pin.animate({
        key: "fill",
        to: fill,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
      clockHand.hand.animate({
        key: "fill",
        to: fill,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    });

    setInterval(function () {
      axisDataItem.animate({
        key: "value",
        to: Math.round(Math.random() * totalDistance),
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }, 2000);

    chart.bulletsContainer.set("mask", undefined);

    powerModeData.forEach(({ powerMode, distance }) => {
      const lowScore = distanceByPowerMode[powerMode] - distance;
      const highScore = distanceByPowerMode[powerMode];

      const axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

      if (!axisRange) {
        console.error("axisRange is undefined");
        return;
      }

      axisRange.setAll({
        value: lowScore,
        endValue: highScore
      });

      const axisFill = axisRange.get("axisFill");
      if (axisFill) {
        axisFill.setAll({
          visible: true,
          fill: am5.color(getColorForPowerMode(powerMode)),
          fillOpacity: 0.9
        });
      }

      const label = axisRange.get("label");
      if (label) {
        label.setAll({
          text: powerMode,
          inside: true,
          fontSize: "0.9em",
          fill: root.interfaceColors.get("background")
        });
      }
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [urlMode, theme, props.data]);

  return <div ref={chartRef} className='h-[300px] mt-4'></div>;
};

function getColorForPowerMode(powerMode: string) {
  const colors: Record<string, string> = {
    off: "rgba(128, 128, 128, 0.4)",
    eco: "rgba(76, 175, 80, 0.4)",
    custom: "rgba(255, 152, 0, 0.4)",
    turbo: "rgba(244, 67, 54, 0.4)",
    bikePlus: "rgba(33, 150, 243, 0.4)",
    hybrid: "rgba(156, 39, 176, 0.4)",
    pedelec: "rgba(63, 81, 181, 0.4)",
    fun: "rgba(255, 235, 59, 0.4)"
  };

  return colors[powerMode] || "rgba(0, 0, 0, 0.4)";
}

export default ChartPowerModeKm;
