import {
  ProductsDetailsSessionDataResponseModels,
  SessionTelemetrySteps,
  Vehicle
} from "../../../Models/API/ProductsDataResponseModels";
import { useEffect, useState, useRef, useCallback } from "react";
import MapRender from "../Map/Components/MapRender";
import { LatLngExpression } from "leaflet";
import "../../../globals.css";
import ChartGPSInfo from "./ChartGPSInfo";
import ChartStateCharge from "./ChartStateCharge";
import ProductServices from "../../../services/ProductServices";
import { Skeleton } from "../../../../components/ui/skeleton";
import { timeout } from "../../../../lib/utils";
import { Card, CardContent } from "../../../../components/ui/card";
import DefaultVehicle from "../../../../assets/images/defaultVehicle.png";
import {
  cacheSession,
  CacheStoreState,
  setPrompts,
  setVehicles,
  useCacheStore
} from "../../../services";
import { Button } from "../../../../components/ui/button";
import {
  CheckCircle2,
  Info,
  Pencil,
  SaveIcon,
  Star,
  Trash2,
  X
} from "lucide-react";
import { Input } from "../../../../components/ui/input";
import { useTranslation } from "react-i18next";
import ChartPowerMotorDistance from "./ChartPowerMotorDistance";
import ChartPowerModeKm from "./ChartPowerModeKm";
// import PlaceholderCustom from "./PlaceholderCustom";
import { format, isSameWeek } from "date-fns";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { toast } from "react-toastify";
import { showSuccessMessageToast } from "../../../../common/toast";

// const types = ["success", "info", "warning", "error"];

export interface SessionDetailsProps {
  sessionId?: string;
  hubId?: string;
  className?: string;
  myBikeName?: string;
}

const SessionDetails = (props: SessionDetailsProps) => {
  const [zoom, setZoom] = useState<number>(12);
  const [selectedSessionDetails, setSelectedSessionDetails] =
    useState<ProductsDetailsSessionDataResponseModels | null>(null);
  const [locations, setLocations] = useState<SessionTelemetrySteps[] | null>(
    null
  );
  const [isFavorited, setIsFavorited] = useState(false);
  const [speed, setSpeed] = useState<SessionTelemetrySteps[] | null>(null);
  const [center, setCenter] = useState<LatLngExpression>([45.464, 9.1895]);
  const [loadingSessions, setLoadingSessions] = useState<boolean>(false);
  const mapRef = useRef<L.Map | null>(null);
  const cache = useCacheStore((state: CacheStoreState) => state.cache);
  const vehicles = useCacheStore((state: CacheStoreState) => state.vehicles);
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState<string>("");
  const { t } = useTranslation();

  const handleFavoriteToggle = () => {
    setIsFavorited((prev) => {
      const newFavoriteStatus = !prev;
      setIsAnimating(true);

      toast.dismiss();

      const toastMessage = (
        <div className='flex items-center justify-center'>
          <span className=' mr-2'>
            {newFavoriteStatus ? (
              <CheckCircle2 className='w-6 h-6 text-green-500' />
            ) : (
              <Info className='w-6 h-6 text-blue-500' />
            )}
          </span>
          {newFavoriteStatus
            ? "Added to favorite sessions"
            : "Removed from favorite sessions"}
        </div>
      );

      toast(toastMessage);

      setTimeout(() => {
        setIsAnimating(false);
      }, 500);

      return newFavoriteStatus;
    });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const centerMapOnLastMarker = () => {
    if (locations && locations.length > 0 && mapRef.current) {
      const lastLocation = locations[locations.length - 1].gpsInfo;
      mapRef.current.setView(
        [lastLocation.latitude, lastLocation.longitude],
        zoom
      );
    }
  };

  const getDetailsForSelectedSession = async (
    sessionId: string,
    hubId: string
  ) => {
    if (sessionId && hubId) {
      try {
        setLoadingSessions(true);
        const sessionDetails =
          await ProductServices.detailsProductsSessionMyBike(hubId, sessionId);
        return sessionDetails;
      } catch (error) {
        console.error("Error fetching session details: ", error);
        return null;
      } finally {
        setLoadingSessions(false);
      }
    } else {
      console.error("No session or hub ID provided.");
      return null;
    }
  };

  // Update session title
  const onUpdateTitleSession = useCallback(
    async (hubId: string, sessionId: string, title: string) => {
      try {
        const response = await ProductServices.updateSessionTelemetry(
          hubId,
          sessionId,
          title
        );
        if (response) {
          showSuccessMessageToast(t("UpdateSessionMessage"));
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error updating session title: ", error);
        return false;
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (props.hubId == null || props.sessionId == null) {
        return;
      }

      setSelectedSessionDetails(null);

      let session = cache.find((x) => x.sessionId === props.sessionId) ?? null;
      if (session == null) {
        session = await getDetailsForSelectedSession(
          props.sessionId,
          props.hubId
        );
        if (session != null) {
          cacheSession(session);
        }
      }

      if (!session) {
        return;
      }

      let vehicle = vehicles?.find((x) => x.hubId === props.hubId) ?? null;

      if (vehicle == null) {
        const vehiclesList = await ProductServices.productsMyBike();
        const vehiclesPromises = vehiclesList.map((vehicle) =>
          ProductServices.detailsProductsMyBike(vehicle?.id)
        );
        const vehicles = await Promise.all(vehiclesPromises);

        for (const vehicle of vehicles) {
          if (vehicle.currentSession?.sessionId) {
            try {
              vehicle.currentSessionDetails =
                await ProductServices.detailsProductsSessionMyBike(
                  vehicle.hubId,
                  vehicle.currentSession.sessionId
                );
            } catch (error) {
              console.log("Failed to load current session", error);
            }
          }
        }

        if (vehicles != null) {
          setVehicles(vehicles);
        }

        vehicle = vehicles?.find((x) => x.hubId === props.hubId) ?? null;

        if (vehicle != null) {
          setVehicle(vehicle.vehicle);
        }
      } else {
        setVehicle(vehicle.vehicle);
      }

      setSelectedSessionDetails(session);
      setSpeed(session.sessionTelemetrySteps);
      setTitle(session.name);
      await timeout(500);

      if (session.sessionTelemetrySteps.length > 0) {
        const sessionLocations = session.sessionTelemetrySteps;
        setLocations(sessionLocations);

        const latitudes = sessionLocations.map((loc) => loc.gpsInfo.latitude);
        const longitudes = sessionLocations.map((loc) => loc.gpsInfo.longitude);

        const latCenter = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
        const lngCenter =
          (Math.min(...longitudes) + Math.max(...longitudes)) / 2;

        setCenter([latCenter, lngCenter]);

        const distance = Math.max(...latitudes) - Math.min(...latitudes);
        const zoomLevel = distance < 0.1 ? 15 : distance < 0.5 ? 13 : 12;
        setZoom(zoomLevel);
      }
    })();
  }, [props.sessionId, props.hubId, cache, vehicles]);

  const handleSaveTitle = async () => {
    if (props.hubId && props.sessionId) {
      const success = await onUpdateTitleSession(
        props.hubId,
        props.sessionId,
        title ?? ""
      );
      if (success) {
        const updatedSessionDetails = await getDetailsForSelectedSession(
          props.sessionId,
          props.hubId
        );
        if (updatedSessionDetails) {
          setSelectedSessionDetails(updatedSessionDetails);
          setTitle(updatedSessionDetails.name);
        }
        setEditingTitle(false);
      } else {
        console.error("Failed to update session title");
      }
    }
  };

  const handleCancelTitleChange = () => {
    setTitle(selectedSessionDetails?.name || "");
    setEditingTitle(false);
  };

  const onDeleteSession = useCallback(
    async (
      hubId: string,
      session: ProductsDetailsSessionDataResponseModels
    ) => {
      const ok = await setPrompts(
        t("ConfirmDelete") + " " + session.name,
        t("DeleteMessage"),
        t("Delete")
      );

      if (ok) {
        await ProductServices.deleteSessionBike(hubId, session.sessionId);
        console.log("Session deleted:", session.sessionId);
        //Delete
        window.location.reload();
      }
    },
    [t]
  );

  function getDayOrDate(date: Date) {
    const today = new Date();
    const isCurrentWeek = isSameWeek(date, today, { weekStartsOn: 1 });

    return isCurrentWeek ? format(date, "EEEE") : date.toLocaleDateString();
  }

  function getFormattedTime(date: Date) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit"
    });
  }

  return (
    <>
      <Card>
        <div className='flex flex-row items-center justify-between text-end font-medium m-2 p-2'>
          {selectedSessionDetails?.startDate ? (
            <>
              {getDayOrDate(new Date(selectedSessionDetails.startDate))} {" - "}{" "}
              {getFormattedTime(new Date(selectedSessionDetails.startDate))}
              <Button
                variant='ghost'
                className='p-2'
                title='Favorite'
                onClick={handleFavoriteToggle}
              >
                {isFavorited ? (
                  <StarFilledIcon
                    className={`text-green-500 h-6 w-6 ${
                      isAnimating ? "favorite-animation" : ""
                    }`}
                  />
                ) : (
                  <Star
                    className={`text-green-500 h-6 w-6 ${
                      isAnimating ? "favorite-animation" : ""
                    }`}
                  />
                )}
              </Button>
            </>
          ) : (
            <div>
              <Skeleton className='mx-1 h-3 w-20 rounded-full' />{" "}
              <Skeleton className='mt-1 h-3 w-40 rounded-full' />{" "}
              <Skeleton className='mx-2 my-1 w-16 h-16 rounded-full' />{" "}
              <Skeleton className='mt-2 h-2 w-20 rounded-full' />{" "}
              <Skeleton className='mt-2 h-2 w-60 rounded-full' />{" "}
            </div>
          )}
        </div>
        <div className='flex flex-row items-center justify-between text-lg sm:text-xl font-bold m-4 mt-1'>
          {selectedSessionDetails ? (
            editingTitle ? (
              <div className='relative w-full '>
                <Input
                  type='text'
                  value={title}
                  onChange={handleTitleChange}
                  className='pl-5 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent w-full'
                  placeholder='Enter session title'
                />

                <div className='absolute right-12 top-1/2 transform -translate-y-1/2'>
                  <Button
                    variant='ghost'
                    title='Save Title'
                    onClick={handleSaveTitle}
                    className='p-0'
                    disabled={!title}
                  >
                    <SaveIcon className='w-10 h-5 text-green-500' />
                  </Button>
                </div>

                <div className='absolute right-3 top-1/2 transform -translate-y-1/2'>
                  <Button
                    variant='ghost'
                    title='Cancel Editing'
                    onClick={handleCancelTitleChange}
                    className='p-0'
                  >
                    <X className='w-10 h-5 text-red-500' />
                  </Button>
                </div>
              </div>
            ) : (
              <div className='flex flex-row justify-between items-center'>
                <div className='truncate'>{selectedSessionDetails.name}</div>
                <div>
                  <Button
                    variant='ghost'
                    title='Edit Title Session'
                    onClick={() => {
                      setEditingTitle(true);
                      setTitle(selectedSessionDetails.name);
                    }}
                    className='ml-2'
                  >
                    <Pencil className='w-6 h-5' />
                  </Button>
                </div>
              </div>
            )
          ) : null}
        </div>
        <div className='col-span-2 sm:col-span-2 m-4'>
          {vehicle && (
            <div className='flex items-center'>
              <img
                src={
                  vehicle?.image?.uri ??
                  vehicle?.modelImage?.uri ??
                  DefaultVehicle
                }
                alt={vehicle?.name || "My Bike"}
                className='w-10 h-10 rounded-full object-cover bg-white'
              />
              <div className='flex grow flex-col items-start p-2'>
                <h2 className='text-sm font-bold truncate max-w-[80px] whitespace-nowrap'>
                  {vehicle?.name}
                </h2>
                <p className='text-xs truncate max-w-[80px] whitespace-nowrap'>
                  {vehicle?.brandName}
                </p>
                <p className='text-xs truncate max-w-[80px] overflow-hidden whitespace-nowrap'>
                  {vehicle?.modelName}
                </p>
              </div>
            </div>
          )}
        </div>
      </Card>

      <h1 className='text-green-500 m-2'>{t("Details")}</h1>

      <Card>
        <CardContent className='justify-between grid grid-cols-2 p-4 gap-4'>
          {selectedSessionDetails ? (
            <>
              <div className='mt-2'>
                <label className='text-sm text-muted-foreground'>
                  {t("Distance")}
                </label>
                <div className='font-medium'>
                  {selectedSessionDetails.totalDistance.toFixed(2)} km
                </div>
              </div>
              <div className='mt-2'>
                <label className='text-sm text-muted-foreground'>
                  {t("Duration")}
                </label>
                <div className='font-medium'>
                  {selectedSessionDetails.totalDuration}
                </div>
              </div>
              <div className='mt-2'>
                <label className='text-sm text-muted-foreground'>
                  {t("AverageSpeed")}
                </label>
                <div className='font-medium'>
                  {selectedSessionDetails.avgSpeed} km/h
                </div>
              </div>
              <div className='mt-2'>
                <label className='text-sm text-muted-foreground'>
                  {t("MaxSpeed")}
                </label>
                <div className='font-medium'>
                  {selectedSessionDetails.maxSpeed} km/h
                </div>
              </div>
              <div className='mt-2'>
                <label className='text-sm text-muted-foreground'>
                  {t("Consumption")}
                </label>
                <div className='font-medium'>
                  {selectedSessionDetails.totalConsumption} wh/km
                </div>
              </div>
              <div className='mt-2'>
                <label className='text-sm text-muted-foreground'>
                  {t("Recovered")}
                </label>
                <div className='font-medium'>
                  {selectedSessionDetails.totalRecovered} wh
                </div>
              </div>
              <div className='mt-4 col-span-2'>
                <div className='flex justify-between text-md font-medium'>
                  <span>Slow</span>
                  <span>Fast</span>
                </div>
                <div className='w-full h-2 bg-gradient-to-r from-green-400 via-yellow-400 to-red-500 mt-2 rounded-full'></div>
              </div>
            </>
          ) : (
            <>
              <Skeleton className='w-[120px] h-5 rounded-full col-span-2' />
              <Skeleton className='w-[80px] h-3 rounded-full col-span-2 mt-4' />
              <Skeleton className='w-[160px] h-4 rounded-full mt-2 col-span-2' />
            </>
          )}
        </CardContent>
      </Card>

      <h1 className='text-green-500 m-2'>{t("Map")}</h1>
      {locations != null ? (
        <>
          <MapRender
            showMarkers={true}
            mapRef={mapRef}
            locations={locations.map((x) => x.gpsInfo)}
            loading={loadingSessions}
            center={center}
            className='h-[400px] rounded-lg overflow-hidden m-1'
            zoom={zoom}
            showMiniMap={true}
            minimapPosition='topright'
            centerMapOnLastMarker={centerMapOnLastMarker}
            telemetryData={speed}
            attributionControl={true}
            disableDragging={false}
          />
          {/* <Button
              onClick={centerMapOnLastMarker}
              title='Map Center'
              about='Map Center'
              className='absolute right-8 bottom-5 rounded-xl px-2 z-[1000]'
              variant={"outline"}
            >
              <Scan />
            </Button> */}
        </>
      ) : (
        <Skeleton className='w-full h-[400px] rounded-lg' />
      )}

      <h1 className='text-green-500 m-2'>{t("Graph")}</h1>

      {selectedSessionDetails != null ? (
        <ChartGPSInfo data={selectedSessionDetails.sessionTelemetrySteps} />
      ) : (
        <Skeleton className='w-full h-[400px] rounded-lg' />
      )}

      {selectedSessionDetails != null ? (
        <ChartStateCharge data={selectedSessionDetails.sessionTelemetrySteps} />
      ) : (
        <Skeleton className='w-full h-[400px] rounded-lg' />
      )}

      {selectedSessionDetails != null ? (
        <ChartPowerMotorDistance
          data={selectedSessionDetails.sessionTelemetrySteps}
        />
      ) : (
        <Skeleton className='w-full h-[400px] rounded-lg' />
      )}

      {selectedSessionDetails != null ? (
        <ChartPowerModeKm data={selectedSessionDetails.sessionTelemetrySteps} />
      ) : (
        <Skeleton className='w-full h-[400px] rounded-lg' />
      )}

      <div className='flex justify-center'>
        {selectedSessionDetails ? (
          <Button
            variant={"outline"}
            className='bg-transparent text-red-600 border-red-600 rounded-full hover:text-red-600'
            onClick={(e) => {
              e.stopPropagation();
              if (
                selectedSessionDetails &&
                props.hubId &&
                selectedSessionDetails.sessionId
              ) {
                onDeleteSession(props.hubId, selectedSessionDetails);
              } else {
                console.error(
                  "Session is not selected or missing hubId/sessionId"
                );
              }
            }}
          >
            {t("Delete")}
            <Trash2 style={{ color: "red", width: 20, height: 15 }} />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default SessionDetails;
