import { useEffect } from "react";
import "../../../globals.css";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { useTheme } from "../../../../components/ui/theme-provider";
import { cn } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";

const powerModeFillColors: Record<string, am5.Color> = {
  off: am5.color("rgba(128, 128, 128, 0.4)"),
  eco: am5.color("rgba(76, 175, 80, 0.4)"),
  custom: am5.color("rgba(255, 152, 0, 0.4)"),
  turbo: am5.color("rgba(244, 67, 54, 0.4)"),
  bikePlus: am5.color("rgba(33, 150, 243, 0.4)"),
  hybrid: am5.color("rgba(156, 39, 176, 0.4)"),
  pedelec: am5.color("rgba(63, 81, 181, 0.4)"),
  fun: am5.color("rgba(255, 235, 59, 0.4)"),
};

const powerModeStrokeColors: Record<string, am5.Color> = {
  off: am5.color("rgba(128, 128, 128, 1)"),
  eco: am5.color("rgba(76, 175, 80, 1)"),
  custom: am5.color("rgba(255, 152, 0, 1)"),
  turbo: am5.color("rgba(244, 67, 54, 1)"),
  bikePlus: am5.color("rgba(33, 150, 243, 1)"),
  hybrid: am5.color("rgba(156, 39, 176, 1)"),
  pedelec: am5.color("rgba(63, 81, 181, 1)"),
  fun: am5.color("rgba(255, 235, 59, 1)"),
};

export interface ChartDataPoint {
  utcDateTime: string;
  stateOfCharge: number;
  distance: number;
  powerMode: string;
  strokeSettings?: {
    stroke: am5.Color;
  };
  fillSettings?: {
    fill: am5.Color;
  };
}

export interface ChartStateChargeProps {
  data: ChartDataPoint[];
  className?: string;
}

const ChartStateCharge = (props: ChartStateChargeProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const searchParams = new URLSearchParams(window.location.search);
  const urlMode = searchParams.get("mode");

  useEffect(() => {
    const root = am5.Root.new("chartdiv");
    const am5themes: am5.Theme[] = [am5themes_Animated.new(root)];
    if (urlMode === null || urlMode === "dark") {
      am5themes.push(am5themes_Dark.new(root));
    } else {
      am5themes.push(am5themes_Animated.new(root));
    }
    root.setThemes(am5themes);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelable: false,
        draggable: false,
        pinchZoomX: false,
        pinchZoomY: false
      })
    );

    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none"
      })
    );
    cursor.lineY.set("visible", false);

    const data = props.data.map((point) => ({
      ...point,
      strokeSettings: {
        stroke: powerModeStrokeColors[point.powerMode] || am5.color(0xff0000)
      },
      fillSettings: {
        fill: powerModeFillColors[point.powerMode] || am5.color(0xff0000)
      }
    }));

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        paddingTop: 16,
        paddingBottom: 16,
        numberFormat: "#.00' Km'",
        max: data.at(-1)?.distance,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50
        })
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 11,
      minPosition: 0.05,
      location: 0,
      maxPosition: 0.95,
      rotation: 45
    });

    // xRenderer.grid.template.set("location", 0.5);
    // xRenderer.labels.template.setAll({
    //   location: 0.5,
    //   multiLocation: 0.5,
    //   fill: textColor
    // });

    // const xAxis = chart.xAxes.push(
    //   am5xy.CategoryAxis.new(root, {
    //     categoryField: "powerMode",
    //     renderer: xRenderer,
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{powerMode}"
    //     })
    //   })

    // );

    //xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        max: 100,
        min: 0,
        width: 48,
        paddingRight: 8,
        numberFormat: "#' %'",
        renderer: am5xy.AxisRendererY.new(root, {
          strokeWidth: 2
        })
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      fontSize: 11,
      location: 0.5
    });

    // Column Series for state of charge
    // const series = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueXField: "distance",
    //     valueYField: "stateOfCharge",
    //     categoryXField: "powerMode",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "{valueY} State of Charge\nPower Mode: {powerMode}",
    //       dy: -5
    //     })
    //   })
    // );

    // series.columns.template.setAll({
    //   templateField: "fillSettings",
    //   strokeWidth: 2
    // });

    // series.data.setAll(data);
    // series.appear(1000);

    // Line Series for state of charge over power modes
    const lineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: t("StateOfCharge"),
        xAxis: xAxis,
        yAxis: yAxis,
        minDistance: 0,
        valueXField: "distance",
        valueYField: "stateOfCharge",
        categoryXField: "powerMode",
        stroke: am5.color("#0x4caf50"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}% " + t("StateOfCharge")
        })
      })
    );

    lineSeries.strokes.template.setAll({
      visible: true,
      strokeWidth: 2,
      templateField: "strokeSettings"
    });

    lineSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.5,
      templateField: "fillSettings"
    });

    // lineSeries.bullets.push(function () {
    //   return am5.Bullet.new(root, {
    //     sprite: am5.Circle.new(root, {
    //       radius: 4,
    //       fill: am5.color(0xff0000) // Colore dei proiettili
    //     })
    //   });
    // });

    lineSeries.data.setAll(data);
    lineSeries.appear(1000);

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [props.data, theme]);

  return (
    <div className={cn(props.className)}>
      <div id='chartdiv' className='h-[300px] mt-4'></div>
    </div>
  );
};

export default ChartStateCharge;
